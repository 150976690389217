/**
 * StartArea.css
 *
 * This is a css used mainly with sibling StartArea.tsx which styles mostly around the central component of StartAGameModal
 */

.gameSelectBackButton {
  font-size: 1.5em;
  width: 1.8em;
  height: 1.8em;
  background-color: #02020a;
  color: #e9e9e9;
  cursor: pointer;
  border: 1px solid #e9e9e9;
  border-radius: 0.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.radioOptions > * {
  padding: 0 0.6em 0.2em 0.4em;
  border-radius: 10px;
  background: #2c2c2c;
  margin: 0 0 0 0.4em;
}

.radioOptions > span > input {
  margin: 0 0.4em 0 0.2em;
}

.radioOptions * {
  cursor: pointer !important;
}

.buttons {
  display: flex;
  margin-top: 15px;
  position: relative;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.startAreaContentsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: 100%;
}

.buttonsStartGame {
  display: flex;
  align-items: center;
  align-self: center;
  position: sticky;
}

.startOptionsContent {
  padding: 0.5em;
}

.backButton {
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
  background-color: #02020b;
  padding: 0.5em 1em;
  border: 1px solid gray;
  position: absolute;
  left: 0;
}

.buttons > *,
.buttonsStartGame > * {
  cursor: pointer;
  border-radius: 5px;
}

.startButtonContainer {
  flex: 1;
  display: flex;
}

.actionButton.saveButton {
  max-width: fit-content;
  font-size: 1.5em;
  font-weight: bolder;
  color: #fff;
  padding: 0.5em 1.5em;
  cursor: pointer;
  border-radius: 12px;
}

.practiceModeCheckbox {
  margin-right: 0.5em;
}

.practiceModeCheckboxText {
  vertical-align: middle;
}

.practiceModeCheckboxPosition {
  width: fit-content;
  pointer-events: none;
  position: absolute;
  left: -11em;
}

.practiceModeCheckboxContainer {
  font-size: 1em;
  font-weight: 700;
  flex: 1 1;
  display: flex;
  width: 100%;
  height: 2em;
  align-items: center;
  position: relative;
  padding-right: 1em;
  pointer-events: all;
}

.practiceModeClickWrapper {
  display: flex;
}

.cardInfo {
  font-size: 0.95rem;
  margin: 0.3rem;
  display: flex;
  gap: 0.65em;
  justify-content: center;
  flex-wrap: wrap;
}

.chipDenomSetupContainer .minBet {
  padding: 0;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdownWrapper.gameFlavor {
  display: inline-block;
}

.dropdownButton {
  font-weight: 500;
  border-radius: 20px;
  padding: 0.3rem 0.6rem !important;
  background: #272d2d;
  border: 2px solid #d5d5d7 !important;
  cursor: pointer !important;
}

.dropdownPopover {
  text-align: initial;
  border-radius: 8px;
  color: #272d2d;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.dropdownPopover [data-reach-listbox-list] {
  margin: 0 1px;
  list-style: none;
  position: relative;
  overflow: auto;
  padding: 0;
  min-width: 9em;
}

[data-reach-listbox-option] {
  display: flex;
  width: 100%;
  justify-content: center;
  display: flex;
}

.dropdownPopover [data-reach-listbox-option][data-current-nav] {
  background: hsl(0deg 0% 88%);
  color: hsl(0deg 0% 0%);
}

.cardRule {
  padding: 0 3px;
}

.dealCardsArea {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.startAreaButtons {
  display: flex;
  justify-content: center;
}

.startAreaButtons.buttonContainer button {
  width: 100%;
  margin: 0em 0.5em;
}

@media only screen and (max-width: 1210px) {
  .practiceModeCheckboxPosition {
    position: inherit;
  }
}
