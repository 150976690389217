.enterName {
  width: 100%;
  height: 100%;
}
.tile {
  display: flex;
  align-items: center;
}

#login-name {
  display: flex;
  flex-direction: column;
  position: relative;
}

#login-name label {
  color: white;
  font-weight: 900;
  text-align: center;
}

.login-container {
  display: flex;
  background-image: url(/src/static/graphics/TableWithoutLogo.png);
  font-size: 1rem;
  background-size: cover;
  margin: auto;
  width: 100%;
  height: 100%;
  justify-content: center;
}

#login-name .nameInput {
  width: 16.8em;
  height: 2.4em;
  align-self: center;
  text-align: left;
  text-indent: 1em;
  font-size: 0.8em;
  line-height: 1.5em;
  font-weight: 900;
  border-width: 0.1em;
  border-color: #d1d5db;
  border-radius: 0.175rem;
  box-sizing: border-box;
}
#login-name .nameInput.error {
  border: 1px solid #d7263d;
  color: #d7263d;
  background: linear-gradient(
      0deg,
      rgba(215, 38, 61, 0.1),
      rgba(215, 38, 61, 0.1)
    ),
    #ffffff;
}
.nameError {
  background: #b53c3c;
  border-radius: 4px;
  color: #ffffff;
  padding: 4px 16px;
  margin-top: 8px;
  font-weight: 700;
  font-size: 0.6em;
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
}
::placeholder {
  color: #d5d5d7;
}

.myButton {
  width: 100%;
  height: 2em;
  background-color: #44c767;
  font-weight: 900;
  color: white;
  cursor: pointer;
}

p {
  margin: 3px;
}

.enterName .extraSessionModal {
  width: 12em !important;
}
.enterName .extraSessionHeadline {
  font-size: 1em !important;
}
.enterName .extraSessionContinueButton.actionButton {
  max-width: 100%;
  font-size: 0.8em;
}
