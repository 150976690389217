.endShowdownArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.botButtons {
  display: flex;
  justify-content: center;
}

.botButtons.buttonContainer button {
  width: 100%;
  margin: 0em 0.5em;
}

.showdownButtons {
  display: flex;
  justify-content: center;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}
.descriptionContainer .buttonDescription {
  font-size: 0.8em;
  margin-top: 0.3em;
}
