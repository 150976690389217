.error-boundary {
  border-radius: 1em;
  background-color: #2c2c2c;
  color: whitesmoke;
  font-size: 1em;
  font-weight: bold;
  display: inline-block;
  padding: 0.5em;
}

.error-message {
  padding: 1em;
  position: fixed;
  top: 0;
  width: 40%;
  background: #6fcd6f;
  border: 2px solid #335e33;
  border-top: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 1em 1em;
  box-shadow: 0 0 5px 1px #10250e;
  text-align: center;
}

.error-dismiss {
  font-weight: bold;
  color: #9a2525;
  cursor: pointer;
  position: absolute;
  top: 0.3em;
  right: 0.7em;
}

.error-icon {
  cursor: pointer;
  font-size: 2em;
  color: #da1313;
  filter: drop-shadow(0px 0px 1px #6e1a1a);
}

.error-icon-holder {
  position: absolute;
  z-index: 21;
}
