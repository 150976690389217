/**
 * ModalConfig.module.css
 *
 * This css in-houses the default classes for different modals created under ModalEntity
 * eg: custom fixed height-width of modal, css over-rides, transitions, etc
 */
.tinyWidthContainer {
  min-width: 40vw;
}

.smallWidthContainer {
  min-width: 50vw;
}

.mediumWidthContainer {
  min-width: 57vw;
}

.largeWidthContainer {
  min-width: 62vw;
}

.fixedHeightContainer {
  min-width: 62vw;
  height: 34em;
}

.tinyTitle {
  font-size: 1.4em;
}

.tinyCloseButton {
  width: 1.75em;
}
