/*

these style rules define how to display the dots which
appear above cards to indicate how many cards a player
has just discarded. hiding them is handled with opacity,
and opacity transition is set to half a second, so that
they fade in and out

*/

.discardCountIndicator {
  position: absolute;
  display: flex;
  top: -0.5em;
  opacity: 1;
  transition: 0.5s opacity;
}
.discardCountIndicator.hideDots {
  opacity: 0;
}
.discardDot {
  width: 0.6em;
  height: 0.6em;
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.3);
  border: 0.15em solid rgba(0, 0, 0, 0.2);
}
.discardDotFull {
  background-color: #e9c000;
  border: 0.15em solid rgba(0, 0, 0, 0.8);
}

.left .discardCountIndicator {
  right: 0.8em;
}
.right .discardCountIndicator {
  left: 0.8em;
}
.left .discardDot {
  margin-left: 0.8em;
}
.right .discardDot {
  margin-right: 0.8em;
}
