.botImage {
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: var(--video-border-radius);
}
.pauseContent {
  filter: none;
  font-size: 1.5em;
}
.pauseOverlay {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: #181818;
  background-color: #3e5255;
  position: absolute;
  z-index: 3;
  border-radius: var(--video-border-radius);
}
.joiningPlaceholder {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.joiningPlaceholderText {
  display: flex;
  padding: 8px 16px;
  font-size: 1em;
  font-weight: 700;
  color: #d5d5d7;
  justify-content: center;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.5);
}
.videoHidden {
  display: none;
}
#TwilioVideoRoomMonitorContainer > div {
  background-color: green;
}
