.error-page {
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  margin: 0;
  padding: 1em;

  background-color: #2c2c2c;

  color: whitesmoke;
  font-size: 1em;
  font-weight: bold;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
