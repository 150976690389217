/**
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * This file specifically styles a custom "Modal" component for styles with/in the modal content.
*/
.getMoreChipsModalSection {
  display: flex;
  justify-content: center;
  padding: 3em;
  border-radius: 13px;
  background-color: #02020b80;
}
