/**
 * GameSelectHeader.css
 *
 * This css is used by GameSelectHeader.tsx to style custom header that is used when starting a game
 */
.gameSelector {
  text-align: center;
  padding: 0 2px;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.gameSelectorHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-top: 0.5em;
}

.gameSelectBackButtonWrapper {
  display: flex;
  justify-content: flex-start;
  width: 4em;
}

.gameSelectBackButton {
  font-size: 1.5em;
  width: 1.8em;
  height: 1.8em;
  background-color: #02020a;
  color: #e9e9e9;
  cursor: pointer;
  border: 1px solid #e9e9e9;
  border-radius: 0.2em;
}

.gameSelectorImage {
  height: 6em;
  width: 6em;
  display: flex;
  justify-content: flex-end;
}

.gameSelectorImage img {
  object-fit: contain;
  object-position: right;
  width: 100%;
}

.gameSelectorTitle {
  color: white;
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 0.2em;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 768px) {
  .gameSelectBackButtonWrapper {
    width: min-content;
  }
  .gameSelectorTitle {
    font-size: 1.5em;
  }
}
