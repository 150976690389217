/**
 * TableLedger.css
 *
 * This is a css used mostly with sibling TableLedger.tsx to style it and it's sub-components
 */
td,
th {
  text-align: end;
}

/* Minimize width of first tr element */
th:first-child,
td:first-child {
  width: 0;
  white-space: nowrap;
}

#ledger {
  width: 100%;
  margin-top: 1em;
}

.tableLedgerTitle {
  font-size: 2em;
  color: white;
  font-weight: bold;
  align-self: center;
  justify-self: center;
}

.tableLedgerContainer {
  flex: 1 1;
  flex-direction: column;
  box-sizing: border-box;
  height: 100%;
}

.tableLedgerContainer .tableContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 0.3em;
  padding-bottom: 3em;
  border-bottom: 1em solid transparent;
}

.tableLedgerRow {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.tableLedgerRow:first-child .leftColumn,
.tableLedgerRow:first-child .centerColumn,
.tableLedgerRow:first-child .rightColumn {
  border-top: 1px solid rgb(255, 255, 255, 0.57);
}
.tableLedgerRow.leftColumn {
  display: flex;
  border-top: none;
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
  border-right: 1px solid rgb(255, 255, 255, 0.57);

  flex: 0.7;
  padding: 0.5em 0em;
}
.tableLedgerRow.centerColumn {
  display: flex;
  border-top: none;
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
  border-right: 1px solid rgb(255, 255, 255, 0.57);

  flex: 0.4;
  padding: 0.5em 0.5em;
}
.tableLedgerRow.rightColumn {
  display: flex;
  border-top: none;
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);

  flex: 1.3;
  padding: 0.5em 0.5em;
}

.boldTableText {
  color: white;
  font-weight: 700;
  font-size: 1.1em;
}

.tableText {
  color: white;
  font-weight: 600;
  font-size: 1em;
}

.playerBankRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.playerBankRowsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  flex: 1;
}

.playerBankIndicator {
  display: flex;
  flex: 1;
  padding: 0.5em;
  flex-direction: column;
  border: 2px solid #ffffff80;
}

.totalBuyInsIndicator {
  border-right: 0;
}

.netPlayerBalanceIndicator {
  border-left: 0;
}

.netPlayerBalanceIndicator .playerBankIndicatorContent {
  background-color: #d7263d;
}

.netPlayerBalanceIndicator .playerBankIndicatorContent.positiveDigit {
  color: #000;
  background-color: #fff;
}

.playerBankIndicatorTitle {
  display: flex;
  color: white;
  font-weight: 700;
  font-size: 1.1em;
  justify-content: center;
  align-items: center;
}

.playerBankIndicatorContent {
  display: flex;
  color: white;
  font-weight: 700;
  font-size: 1.3em;
  justify-content: center;
  align-self: center;
  padding: 0 1rem;
  margin-top: 0.2rem;
  border-radius: 4px;
}

.chipRequestContainer {
  display: flex;
  text-align: center;
  flex: 2;
  padding: 0.7em;
  flex-direction: column;
  border: 2px solid #ffffff80;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
  min-height: 7.35em;
}

.chipRequestContainer.notification {
  border: 4px solid #f0c105;
}

.chipRequestContainer.inactive {
  filter: opacity(0.7) brightness(70%);
  cursor: default;
  pointer-events: none;
}

.chipRequestButtons {
  display: flex;
  gap: 0.5em;
  width: 100%;
  flex: 1;
}

.chipRequestButton.actionButton.green {
  display: flex;
  flex-direction: column;
  width: fit-content;
  font-weight: 700;
  font-size: 1.1em;
  padding: 1em 0;
  flex: 1;
}

.chipAmount {
  font-size: 1.5rem;
  margin: 0.25rem;
}

.cashMeOutButton.actionButton.playerBank {
  flex: 1;
  font-size: 1.5em;
  font-weight: 700;
  color: #d5d5d7;
}

.cashMeOutButton.actionButton.playerBank:hover {
  background: #f0c105;
  color: #272d2d;
  border-color: #ffffff80;
}

.navigateBack {
  color: #fff;
  cursor: pointer;
  font-size: 2.5em;
  position: absolute;
}

.cashMeOutContainer.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  height: 100%;
  flex: 1 1;
  grid-gap: 0.7em;
  align-self: center;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;
}

.cashMeOutText {
  display: flex;
  color: white;
  font-size: 1.5em;
  justify-self: center;
  align-self: center;
}

.leaveGameButton.actionButton.green {
  width: fit-content;
  font-weight: 700;
  font-size: 1.5em;
  padding: 0.5em 1em;
  flex: 0;
}

.cancelButton.actionButton.playerBank {
  color: white;
  font-size: 1.1em;
  text-align: center;
  width: fit-content;
  padding: 0.5em 2em;
  flex: 0;
}

.cancelButton.actionButton.playerBank:hover {
  background: #f0c105;
  color: #272d2d;
  border-color: #ffffff80;
}

.requestChipsSelect {
  padding: 0.5em;
  border-radius: 0.4em;
  font-weight: bold;
}

.notificationText {
  font-size: 1.4em;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.notificationText .amount {
  font-size: 2rem;
}

.playerCashMeOutContent {
  padding-left: 0.5rem;
  display: flex;
  flex: 1 1;
}

.buyChipTooltip {
  margin: 0.25rem 0;
}

.chipNotification {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #fff;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #272d2d;
  font-size: 1.2rem;
  padding: 0.5rem;
  position: relative;
  flex-direction: column;
  color: #fff;
}

.chipNotification .close {
  position: absolute;
  right: 0.5em;
  top: 0.1em;
  font-size: 1.5em;
  cursor: pointer;
}

.chipNotification .title {
  font-size: 1.5rem;
  font-weight: 700;
}

.danger {
  border: 4px solid #d7263d;
}

.info {
  border: 4px solid #f0c105;
}
