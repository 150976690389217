.hostGameWidget {
  box-sizing: border-box;
  border-radius: 0.75em 0px 0px 0.75em;
  display: flex;
  flex-direction: column;
  background-color: #333d32;
  border: 2px solid #272d2d;
  padding: 0.75em;
  width: 100%;
  height: fit-content;
  gap: 1em;
}

.hostGameWidgetText {
  color: #f0c105;
  font-weight: 700;
  font-size: 1em;
  text-align: center;
  justify-self: center;
}
