.extraSessionBackground {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.extraSessionModal {
  background: #323e32;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  padding: 1.5em;
  max-height: 50em;
  width: 26em;
  align-self: center;
  justify-content: center;
  text-align: center;
  z-index: 1;
}

.extraSessionHeadline {
  color: white;
  font-weight: 700;
  font-size: 1.6em;
}

.extraSessionSubtext {
  color: #bbb;
  font-weight: 700;
  line-height: 1.4em;
}

.extraSessionContinueButton.actionButton {
  padding: 0.5rem 2rem;
  align-self: center;
  font-size: 1.3em;
}

.mainLogo {
  background-image: url(/src/static/graphics/Logo35Percent.svg);
  background-position: center;
  background-size: 30%;
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-repeat: no-repeat;
}

/* screen specific */
.extraSessionModal.paymentPlanSelectModal {
  max-width: 40rem;
  width: 100%;
  border: 0.1rem solid rgba(0, 0, 0, 0.3);
  grid-gap: 0.5rem;
  position: relative;
  padding: 0.75rem;
}
.extraSessionModal.paymentPlanSelectModal .extraSessionHeadline {
  font-size: 2rem;
}
