/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for the profile tab of the account page. It may have duplicated CSS with the billing tab, but we don't
 * want to abstract prematurely.
 */

.tabContent {
  color: white;
}

.tabContent > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.tabContent > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1b1f1f;
  border: 2px solid #808085;
  border-radius: 1rem;
  padding: 0.75rem;
}

.tabContent > ul > li button:last-of-type {
  max-width: 240px;
  width: 100%;
}

.labelAndValue {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.4rem;
}

.tabContent > ul > li h6 {
  margin: 0;
  font-size: 1rem;
  color: #f0c105;
}

.tabContent > ul > li span {
  font-size: 0.75rem;
}

.multiButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 0.4rem;
  flex: 1;
}

.ellipsize {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overlayClass {
  top: 0 !important;
  background-color: #5f8358;
  background-image: url(/src/static/graphics/TableWithBackgroundWithoutLogo.jpg);
  background-position: center;
  background-size: cover;
}

.titleClass {
  font-size: 1.3em;
}

.closeButtonClass {
  width: 1.75rem;
  top: 0.6rem;
}

.mailSendText {
  text-align: center;
  font-weight: 700;
  font-size: 0.7em !important;
  padding: 0 0.15em;
}
