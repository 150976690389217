.container {
  margin: 2em auto;
  max-width: 95%;
  color: white;
  margin: auto;
  font-size: 0.95em;
  padding: 1em;
  flex-direction: column;
  gap: 1em;
  overflow: auto;
}

.header {
  color: #efc005;
  font-weight: bold;
  text-align: center;
}

.container table {
  width: 100%;
  border: 0;
  border-spacing: 0;
  background: rgba(0, 0, 0, 0.7);
}

.container table td,
.container table th {
  padding: 0.5em 1em;
  text-align: center;
  vertical-align: top;
}

.container table tbody tr:hover {
  background-color: #efc005;
  color: black;
}
