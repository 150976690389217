.playerNotification {
  width: 64vw;
  height: 35em;
  overflow: auto; /* comment this out to remove scroll bar rules */
  position: fixed;
  top: 47%;
  left: 50%;
  transform: translateX(-50%) translateY(-55%);
  background: #323e32;
  z-index: 10;
  max-height: 30%;
  max-width: 40%;
  box-sizing: border-box;
  border-radius: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
}
.playerNotificationTitle {
  position: relative;
  color: white;
  font-size: 2em;
  font-weight: 700;
}

.playerNotificationText {
  width: 90%;
  position: relative;
  bottom: 0.5em;
  font-size: 1.2rem;
  font-weight: 700;
  color: #ccced0;
}
