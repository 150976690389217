.chooseCardContainer {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  flex: 1;
  gap: 1em;
  grid-gap: 1em;
  align-items: center;
  height: 100%;
}

.selectCardsContainer {
  width: "165px";
  align-self: "center";
  display: "flex";
  flex-direction: "column";
  flex: 1;
}

.selectCards {
  color: #e9c000;
  font-size: 1em;
  font-weight: bold;
}

.skipSelectButton {
  height: min-content;
  background-color: #3f9e0e;
  font-weight: bold;
  border-width: 3px;
  border-style: solid;
  border-color: #96ce8f;
  color: #fff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  width: inherit;
  text-align: center;
  cursor: pointer;
  margin-top: 0.1em;
}

.passTheseCardsButton {
  --size: 1.8;
  height: calc(2.85rem * var(--size));
  background-color: transparent;
  font-weight: bold;
  color: #fff;
  border: none;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 0.95em;
  width: 8em;
  text-align: center;
  cursor: pointer;
  position: relative;
}
.passTheseCardsButton img {
  height: 100%;
  width: 100%;
}
.passTheseCardsButton > svg {
  fill: #1c8f1e;
  stroke: #7bc780;
}
.passTheseCardsButton > svg:hover {
  fill: #6bb770;
  stroke: #8bd790;
}
.passTheseCardsButtonText {
  position: absolute;
  pointer-events: none;
  left: 45%;
  transform: translateX(-50%);
}
.deselectCardButton {
  position: absolute;
  display: block;
  z-index: 1;
  right: 0;
  top: 0;
  background-color: #e9c000;
  color: #333;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.1em 0.3em;
  margin: 0.1em;
  border: none;
  font-size: 1em;
  cursor: pointer;
  animation: lowerRemoveButton 1s ease-in-out;
}
.blankCard,
.discardCardContainer.texDiscardCardContainer {
  transition: none;
  animation: none;
  box-sizing: border-box;
  background-color: transparent;
  border-style: dotted;
  border-color: gray;
}
.texDiscardCardContainer .blankCard {
  border: none;
}
.discardCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 2;
  margin-right: 0.3em;
}
.deselectCardButton:hover {
  background-color: #cbab00;
}
.cardAndDeselectContainer {
  position: relative;
}

.discardContainer {
  flex: 3;
  width: 100%;
}

.fiveCardDrawSkipButtonContainer {
  display: flex;
  padding: 1.4em;
  justify-content: center;
  border: 2px dashed gray;
}

.fiveCardDrawSkipButton {
  border-radius: 8px;
  width: 13em;
}

@keyframes lowerRemoveButton {
  0% {
    transform: translateY(-75%);
  }
  100% {
    transform: translateY(0);
  }
}
