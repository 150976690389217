.mutedIndicator {
  position: absolute;
  z-index: 6;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 1em;
  padding: 0.2em;
  top: 0.2em;
  border-radius: 1em;
}
.middle .mutedIndicator {
  left: 0.2em;
}
.left .mutedIndicator {
  left: 0.2em;
}
.right .mutedIndicator {
  right: 0.2em;
}
