/**
 * Modal.css
 *
 * This is a CSS file which inhouses the css around the overlay, container, header, content, footer and buttons
 * The css is further used by children inside components and thus it's not made into a module
 */
.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
  overflow: hidden !important;
}
.modalOverlay.disabled {
  pointer-events: none;
  cursor: default;
}
.modalOverlay.fullPage {
  top: -12%;
}
.modalOverlay.center {
  top: 44%;
  left: 50%;
  height: min-content;
  width: min-content;
  transform: translateX(-50%) translateY(-50%);
  background: none;
}
.modalOverlay.overlayTableBoard {
  top: 0 !important;
  background-color: #5f8358;
  background-image: url(/src/static/graphics/TableWithBackgroundWithoutLogo.jpg);
  background-position: center;
  background-size: cover;
}

.modalContainer {
  max-width: 62vw;
  max-height: 37em;
  position: relative;
  border: 2px solid #272d2d;
  box-sizing: border-box;
  border-radius: 20px;
  margin: 0em !important;
  width: auto !important;
  background: #323e32 !important;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}

.modalHeaderWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 2.6em;
}
.modalHeader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0;
}

.modalTitle {
  font-weight: 700;
  font-size: 2em;
  color: #fff;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.modalTitle.isBothButtonsVisible {
  max-width: calc(100% - 3.3em * 2);
}
.modalTitle.isOneButtonVisible {
  max-width: calc(100% - 3.3em);
}

.modalBack {
  position: absolute;
  left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  background-color: transparent;
  transition: 0.3s ease all;
  border-radius: 0.5em;
  color: #d5d5d7;
  cursor: pointer;
  z-index: 1;
  background: #02020b;
  padding: 6px 10px;
  border: 1px solid #808085;
}
.modalBack:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.modalBack svg {
  width: 100%;
  height: 100%;
}

.modalClose {
  position: absolute;
  right: 1rem;
  width: 2rem;
  border: none;
  background-color: transparent;
  transition: 0.3s ease all;
  border-radius: 0.5em;
  color: #d5d5d7;
  cursor: pointer;
  z-index: 1;
  padding: 0;
}
.modalClose:hover {
  color: rgba(0, 0, 0, 0.2);
}
.modalClose svg {
  width: 100%;
  height: 100%;
}

.modalContent {
  display: flex;
  flex-direction: column;
  color: #d5d5d7;
  flex: 1 1;
  overflow: auto;
  padding: 0 1rem;
  padding-bottom: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.modalContent.noHeader {
  max-height: 37em;
}

::-webkit-scrollbar {
  width: 24px;
}
::-webkit-scrollbar-thumb {
  border: 8px solid transparent;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.4);
  background-clip: content-box;
}
::-webkit-scrollbar-track {
  border: 8px solid transparent;
  border-radius: 12px;
  margin-right: 8px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.1);
  background-clip: content-box;
}

.modalFooter {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
}

.modalFooter.greenBackground {
  background: #323e32;
}
.modalFooter.blackBackground {
  background: #1a201e;
}

.modalButtonPrimary {
  display: block;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.5em 2em;
  border-radius: 10px;
  border: 0.125rem solid #ffffff80;
  color: #ededed;
  background-color: rgba(26, 158, 39, 1);
  transition: 0.3s ease all;
  cursor: pointer;
  z-index: 1;
}
.modalButtonPrimary:hover {
  background-color: #7eb357;
}

.modalButtonSecondary {
  display: block;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.5em 2em;
  border-radius: 10px;
  border: 0.125rem solid #ffffff80;
  color: #ededed;
  background-color: #3a8de0;
  transition: 0.3s ease all;
  cursor: pointer;
  z-index: 1;
}
.modalButtonSecondary:hover {
  background-color: #5baae7;
}

.modalButtonDanger {
  display: block;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.5em 2em;
  border-radius: 10px;
  border: 0.125rem solid #ffffff80;
  color: #ededed;
  background-color: #b53c3c;
  transition: 0.3s ease all;
  cursor: pointer;
  z-index: 1;
}
.modalButtonDanger:hover {
  background-color: #c45e5e;
}

.modalButtonWarning {
  display: block;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.5em 2em;
  border-radius: 10px;
  border: 0.125rem solid #f0c105;
  color: #f0c105;
  background-color: #02020b;
  transition: 0.3s ease all;
  cursor: pointer;
  z-index: 1;
}
/* .modalButtonWarning:hover {
  background-color: #f0c105;
  color: #ededed;
} */

.modalButtonDark {
  display: block;
  font-size: 1.2em;
  font-weight: bolder;
  padding: 0.5em 2em;
  border-radius: 10px;
  border: 0.125rem solid #ffffff80;
  color: #ededed;
  background-color: #02020b;
  transition: 0.3s ease all;
  cursor: pointer;
  z-index: 1;
}
.modalButtonDark:hover {
  background-color: #1b1b1b;
}

@media only screen and (max-width: 768px) {
  .modalOverlay.center {
    top: 50%;
  }
  .modalContainer {
    min-width: 100vw !important;
  }
  .modalTitle {
    font-size: 1.5em;
  }
}
