/**
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * This file specifically styles a top-level element, the container for all of the hand rank's cards, the hand rank's
 * title and summary, the cards themselves, and modifiers for the cards (like `wild` and `translucent`).
*/

.container {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  list-style: none;
  margin: 0;
  padding: 1em;
}

.hand {
  display: flex;
  gap: 1.25rem;
  padding-right: 0.5rem;
}

.handCards {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  gap: 0.25em;
  padding: 1.5rem 1.25rem;
  border-radius: 10px;

  background-color: #538654;
}

.translucent {
  opacity: 0.35;
}

.wild {
  background-image: url(/src/static/graphics/wildcard.svg);
  background-size: contain;
  background-position: center;
}

.handTitle {
  color: #ffffff;
  margin: 0;
}

.handSummary {
  color: #d5d5d7;
}

/** We don't want animations for these cards. */
.handCards .handRankCard {
  animation: none;
  transition: none;
}

@media only screen and (max-width: 768px) {
  .handCards {
    width: 10em;
  }
}
