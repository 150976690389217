.anteActions {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  padding-top: 1em;
}

.centeredContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: justify;
}

.centeredContainer button {
  width: 5em;
}
