.handContainer {
  display: flex;
}

.cards .myHandHighValue {
  box-sizing: border-box;
  z-index: 20;
  bottom: 0;
  left: 0;
  top: unset;
  right: unset;
  min-width: 100%;
  transform: unset;
}

.right .cards .myHandHighValue {
  right: 0;
  left: unset;
}

.selecting:not(.cardDiscarded) {
  transform: translate(-50%, -110%) !important;
  transition: transform 1s;
}

.selecting .card {
  cursor: pointer;
  animation: selectingGlow 2s ease-in-out infinite;
}

.selecting .card.cardDiscarded,
.selecting .selectingDiscard .card:not(.hole-card) {
  cursor: default;
  animation: none;
  transform: scale(1);
}

.wrapper {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.selecting .card.hole-card:hover:not(.cardDiscarded) {
  transition: all 0.2s ease-in-out;
  transform: scale(1.2);
}

@keyframes selectingGlow {
  0% {
    filter: drop-shadow(0px 0px 8px #ffef41);
  }
  50% {
    filter: drop-shadow(0px 0px 8px rgba(218, 165, 32, 0.9));
  }
  100% {
    filter: drop-shadow(0px 0px 8px #ffef41);
  }
}

@keyframes float {
  0% {
    transform: scale(1.1) translate(-50%, -50%);
  }
  50% {
    transform: scale(1.1) translate(-50%, -60%);
  }
  100% {
    transform: scale(1.1) translate(-50%, -50%);
  }
}

.droppedCardIndicator {
  padding: 4px 10px;
  background: #272d2d;
  border-radius: 4px;
  color: white;
  position: absolute;
  font-size: 0.75em;
  top: -0.95rem;
  z-index: 1;
  left: -1em;
  font-weight: 600;
  margin-left: calc(var(--card-margin) * -1);
}

.right .droppedCardIndicator {
  right: 0;
  left: unset;
}
