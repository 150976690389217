/**
 * This file represents the styles for the sibling component.
 * It is a component that represents modal content which essentially looks like a title, blob of text, and footer with
 * 2 buttons. One is full width and green, and another is content width and gray.
 * Figma Link: https://www.figma.com/file/RPfXM0eOC23wvmMby2oGBD/Poker-Cows---Final-Screens?node-id=2857%3A186466&t=tTkyeWEJDwawc5Og-4
 */

.topLevelElement {
  font-size: 1.125rem; /* 24px */
  text-align: center;
}

.warningText {
  margin-top: 1.5rem; /* 32px */
  margin-bottom: 1.5rem; /* 32px */
}

.warningText > *:first-of-type {
  margin-bottom: 0.75rem; /* 16px */
}

.bold {
  font-weight: 700;
}

.footerWithButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.75rem; /* 16px */
}

/* buttons */
.footerWithButtons > * {
  font-weight: 700;
  padding: 0.575rem 1.125rem; /* 12px 24px */
}

.continueButton {
  font-size: 1.325rem; /* 28px */
}
