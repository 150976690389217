/*
* WaitingNextGame.css
* This styles used to css for WaitingNextGame component
*/
.waitingNextGame {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}
