/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for the account page which renders a UI that looks similar to a modal, but does not act as one.
 */

.accountPage {
  position: relative;
  display: grid;
  place-items: center;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
}

.accountPage * {
  box-sizing: border-box;
}

.headerPanel {
  position: fixed;
  top: 0px;
  width: 100%;
  left: 0px;
  background-color: #000000;
  padding: 0.5em 1em;
  display: flex;
}

.pokerCowLogo {
  background: url(/src/static/graphics/PokerCowsLogo.webp);
  background-size: contain;
  flex: 1;
  background-repeat: no-repeat;
  height: 4em;
}

.logoutButton {
  align-self: center;
  color: #c3c3c4;
}

.userButtonIcon {
  height: 0.95em;
  margin-right: 6%;
}

.sidePanel {
  position: absolute;
  right: 0.75rem;
  top: 6rem;
  /* scales with viewport width to not block the modal content on smaller viewports */
  max-width: 12rem;
  width: 100%;

  background-color: #000000;
  border: 3px solid #c63943;
  border-radius: 1rem;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}

.sidePanel > * {
  flex: 1;
}

.sidePanelDivider {
  text-align: center;
  font-weight: 700;
}

.joinGameForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
}

.joinGameForm input {
  height: 2em;
  font-size: 1rem;
  text-align: center;
  font-weight: 700;
  border-radius: 0.4rem;
  max-width: 100%;
}

.joinGameForm input::placeholder {
  font-size: 0.75rem;
  color: #c1c1c1;
}

.joinGameForm > * {
  width: 100%;
}

.elementWithModalAppearance {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;
  margin: 0 auto;
  width: 100%;
  max-width: 40rem;
  height: 100%;
  max-height: 30rem;
  padding: 0.75rem;
  background: #333d32;
  border: 0.1rem solid #272d2d;
  border-radius: 1.25rem;
}

.elementWithModalAppearance > * {
  width: 100%;
}

.tabsListAndHeader {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
  margin-bottom: 0.15rem;
  font-weight: 700;
}

.header > h2 {
  font-size: 2rem;
  color: #ffffff;
}

.header > p {
  font-size: 0.75rem;
  color: #f0c105;
}

.header * {
  margin: 0;
}

.tabsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.4rem;
  width: 100%;
}

.tabsList > button[role="tab"] {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
  background: #272e29;
  border: 0.1rem solid #808085;
  border-radius: 0.4rem;
}

.tabsList > button[role="tab"][data-state="active"] {
  background-color: #f0c105;
  border-color: #f0c105;
  color: #414148;
}

.tabsList > button[role="tab"][data-state="inactive"] {
  color: #d5d5d7;
}

.tabsList > button[role="tab"][data-state="inactive"]:hover {
  background-color: #3b453e;
  color: #d5d5d7;
}

.homeButton {
  color: #d5d5d7;
  width: auto;
}

.hostButton {
  width: 100%;
}

.linkHostButton {
  text-decoration: none;
}
