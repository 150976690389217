.profilepic {
  float: left;
  margin-right: 0.5em;
}

.usertext {
  font-size: 0.5em;
  margin: 0px;
  float: left;
  color: #ffffff;
}

.userTextLink {
  text-decoration: none;
  color: #ffffff;
}

.profileusername {
  font-size: 1.5em;
  color: #ffffff;
}

.profileavatar {
  width: 1.5em;
  object-fit: cover;
  border-radius: 50%;
}

.profileInfo {
  width: fit-content;
}
