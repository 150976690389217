.chipContainer {
  border: 1px solid black;
  background-color: black;
  outline-offset: -0.5em;
  border-radius: 100%;
  width: 3.2em;
  height: 3.2em;
  color: white;
  line-height: 3.2em;
  justify-content: center;
  align-content: center;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  font-size: 1.25em;
}
.chipAreaRow .chipContainer.disabled {
  filter: opacity(0.7) brightness(70%);
  cursor: default;
  pointer-events: none;
}
.chipAreaRow .chipContainer {
  cursor: pointer;
}
.chipContainer.raise {
  line-height: 0.9em;
}
.raisePlus {
  margin-top: 0.5em;
  font-weight: bold;
}
.chipContainer.lowChip {
  outline: 0.25em solid white;
}
.chipContainer.midChip {
  outline: 0.25em solid #fb0007;
}
.chipContainer.highChip {
  outline: 0.25em solid #299e03;
}
.chipInnerContainer {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 0.2em solid red;
}
.chipTitle {
  color: #fff;
  font-weight: bold;
  clear: both;
  text-align: center;
}
.chipTitleLarge {
  font-size: 1em;
}
.chipTitleMedium {
  font-size: 0.9em;
}
.chipTitleSmall {
  font-size: 0.8em;
}
