.loading {
  width: 100%;
  height: 100%;
}
.loading .extraSessionHeadline {
  font-size: 1em;
}
.loading .extraSessionModal {
  width: fit-content;
}
