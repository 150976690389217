.betArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 3;
  height: 100%;
}

.fixedBetArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 3 1;
  height: 40%;
}

.topRowBetButtons {
  flex: 2;
}
.bottomRowBetButtons {
  flex: 2;
}

.topRowBetButtons,
.middleRowBetSlider,
.bottomRowBetButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.hidden {
  visibility: hidden;
}

.topRowBetButtons .actionButton {
  width: 22%;
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  text-align: center;
  border: 1px white solid;
  border-radius: 5px;
}

.middleRowBetSlider {
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  border-radius: 10px;
  padding: 5px;
  justify-content: space-evenly;
}

.currentSliderAmount {
  font-weight: bolder;
}

.inputDiv {
  top: 0.1em;
  position: relative;
  width: 90%;
}

.inputDiv input {
  width: 98%;
}

.bottomRowBetButtons {
  flex: 3;
}

.bottomRowBetButtons .actionButton {
  background-color: rgba(0, 0, 0, 0.55);
  color: white;
  text-align: center;
  border-radius: 5px;
  border: 0;
  width: 6.2em;
  max-width: 7em;
  min-width: 1em;
  margin: 0 0.2em;
}

.middleRowBetSlider input[type="range"] {
  cursor: pointer;
}

.middleRowBetSlider input[type="range"]:hover {
  filter: brightness(0.8);
}
