.middleRowBetSlider {
  flex: 1 1;
}

.sliderRowContainer {
  display: flex;
  margin: 0 0 0.6em 0;
}
.betInc:hover,
.betDec:hover {
  color: white;
  filter: opacity(1);
}

.betInc.disabled,
.betDec.disabled {
  background: #2c2c2c;
  pointer-events: none;
}

.betInc {
  background: rgba(4, 58, 0, 0.59);
}

.betDec {
  background: rgba(58, 0, 0, 0.62);
}

.betInc,
.betDec {
  user-select: none;
  cursor: pointer;
  filter: opacity(0.7);
  border: 1px solid rgba(0, 0, 0, 0.66);
  padding: 0 0.4em;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin: 0.1em;
}

.betDec {
}
