#gameArea {
  display: flex;
  box-sizing: border-box;
  padding: 1.2rem;
  height: 100%;
  width: 100%;
  min-width: max-content;
  position: absolute;
  left: 0;
}

.gameInfoHeader {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-size: 0.7em;
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1em 2em;
  border-radius: 8px;
  min-width: max-content;
  gap: 0.2em;
  /**
  * We add z-index as 2 here to have the third-highest z-index among middle components
  */
  z-index: 2;
}

.gameInfoHeader .game {
  font-size: 1.5em;
  font-weight: 800;
}

.gameInfoHeader .gameInfoText {
  display: flex;
  gap: 0.3em;
  font-weight: 500;
  color: #eaeaea;
}

.gameInfoHeader .gameInfoLabel {
  color: #f0c105;
  text-align: right;
  font-weight: bold;
  width: 2rem;
}

.gameInfoHeader .gameInfoValue {
  text-align: left;
  white-space: nowrap;
}

.rulesDocumentationButton {
  cursor: pointer;
  background-color: white;
  border-bottom: solid;
  border-width: thin;
  border-radius: 5px;
  cursor: pointer;
  font-size: small;
  color: black;
  padding: 0.1em 0.5em;
  margin: 0px 10px;

  /* z-index: 21; */
}
.rulesText {
  font-size: small;
  text-align: left;
  overflow: auto;
  max-height: 99%;
}

.gamePrefsInfoWrapper {
  font-size: 0.8em;
  font-weight: 700;
  color: #d5d5d7;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamePrefsInfo {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  font-weight: 700;
  color: #d5d5d7;
}

.gamePrefsItem {
  white-space: nowrap;
}

.gamePrefsItem:not(:last-child) {
  margin-right: 0.6em;
  padding-right: 0.6em;
  border-right: 2px solid white;
  height: 1.2em;
}

.gameName {
  color: white;
  font-weight: bold;
  font-size: 2em;
  align-self: center;
}

.chip-border {
  box-shadow: 0 0 0 2pt black;
}

.chip:hover {
  box-shadow: 2px 2px 8px 1px black;
}

.chip:active {
  box-shadow: 0 0 0 0 #000000;
}

.refresh {
  display: none;
}

.hidden {
  transition: opacity ease-in-out 1s;
  opacity: 0;
}

.chip,
.open-seat {
  cursor: pointer;
}

.chip {
  user-select: none;
  background-image: url(/src/static/graphics/chip.svg);
  border-radius: 50%;
  color: white;
  font-weight: bolder;
  text-shadow: 0 1px 2px black;
  background-size: auto 100%;
  box-shadow: 0px 0px 5px 1px black;
}

.left,
.right {
  max-height: 100%;
  min-height: 0;
  width: min-content;
  display: flex;
  box-sizing: border-box;
}

.middle {
  text-align: center;
  padding: 0 1rem;
  flex: 1;
}

.zIndexLow {
  z-index: 1;
}
.zIndexHigh {
  z-index: 2;
}

.left,
.middle,
.right {
  gap: 1.2rem;
  display: flex;
  flex-direction: column;
  position: relative;
}

.middle {
  text-align: center;
  padding: 0 1rem;
  flex: 1;
  /* The reason we have z-index lower than that of left and right is to handle action on cards after showdown */
  z-index: 1;
  gap: 0.2rem;
}

.right,
.right .seatingArea {
  align-items: flex-end;
}

.seatingArea {
  gap: 0.4rem;
  justify-content: space-between;
}

.right .seatingArea,
.left .seatingArea {
  display: flex;
  flex: 1 0 10px;
  justify-self: stretch;
  flex-direction: column-reverse;
}

.right .seatingArea {
  flex-direction: column;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
}

.buttonsBottom {
  height: 7rem;
  gap: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.breakButton {
  white-space: break-spaces;
}

.buttonsBottomLeft {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
}

.buttonsBottomRow {
  flex-direction: row;
  display: flex;
  width: 100%;
  max-width: 14rem;
  justify-content: space-between;
  flex: 0.6;
  margin-bottom: 1em;
}

.buttonsBottomRow.buttonsBottomRowCentered {
  justify-content: space-around;
  flex: 0.2;
  margin-bottom: 0;
}

.right .buttonsBottom .actionButton {
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  flex: 1;
}
.right .buttonsBottom .actionButton.break {
  font-size: 0.75em;
  min-width: 6.5em;
}

.left .buttonsBottom .actionButton {
  text-align: center;
  font-weight: 600;
  width: 100%;
}

.buttonsBottomRow > div {
  display: flex;
  flex: 0.48;
}

.right .buttonsTop {
  align-self: flex-end;
}

.right .seatingArea {
  justify-content: space-evenly;
}

.pot,
.player-bet {
  font-weight: bolder;
  color: #ffffff;
  font-size: 1.1rem;
}

.buttonsBottom .menu {
  display: flex;
}
.buttonsBottom .actionButton {
  border-radius: 0.4rem;
}

.startGame {
  color: rgba(216, 216, 216, 1);
  font-size: 1.6rem;

  width: 3rem;
  height: 3rem;
  position: relative;

  background-image: url(/src/static/graphics/ButtonUIBlank.svg) !important;
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;
}
.pauseGameButton {
  white-space: pre-line;
}

.mainLogo {
  background-image: url(/src/static/graphics/Logo35Percent.svg);
  background-position: center;
  background-size: 30%;
  position: absolute;
  pointer-events: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-repeat: no-repeat;
}

.gameSession {
  color: white;
  background: #b63b3d;
  display: flex;
  justify-content: center;
  padding: 0.4rem 0.4rem;
  border-radius: 8px;
  align-self: center;
  align-items: center;
  font-size: 0.85rem;
}

.gameSession .gameSessionTime {
  padding: 0 0.5rem;
  font-weight: 700;
}

.gameSession .gameSessionTime .timerNumbers {
  font-size: 1em;
}

.gameSession .close {
  margin-left: 0.5rem;
}

.gameSession .close-icon {
  font-size: 1.5em;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  #gameArea {
    padding-bottom: 0.6rem;
  }
  .left,
  .middle,
  .right {
    gap: 0.1em;
  }
  .buttonsBottom {
    max-height: 5em;
  }
  .media-controls {
    gap: 0.1em;
  }
  .media-controls .actionButton {
    max-height: 1.5em;
  }
}
