.addPlayer {
  margin-left: 2px;
}

.hasAction {
  color: #e5d951;
}

.home {
  margin-left: auto;
}

.devTabs div {
  background: rgb(80, 80, 80);
  padding: 0.1em 0.5em;
  border: 1px solid #464343;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  user-select: none;
}

.devTabs div:hover {
  background: rgb(70, 70, 70);
}

.activeTab {
  background: rgb(44, 44, 44) !important;
}

.devTabs {
  height: min-content;
  width: 100%;
  background: rgb(104, 148, 95);
  border-bottom: 3px solid rgb(44, 44, 44);
  color: #d1d5db;
  display: flex;
  z-index: 50;
}

.devTables {
  position: relative;
  height: 90%;
  width: 100%;
  flex: 1 1 auto;
}

.devTable {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

.devPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
