/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for the billing tab of the account page. It may have duplicated CSS with the profile tab, but we don't
 * want to abstract prematurely.
 */

.tabContent {
  color: white;
}

.tabContent > ul {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
}

.tabContent > ul > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1b1f1f;
  border: 2px solid #808085;
  border-radius: 1rem;
  padding: 0.75rem;
}

.tabContent > ul > li button:last-of-type {
  max-width: 240px;
  width: 100%;
}

.labelAndValue {
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.4rem;
}

.tabContent > ul > li h6 {
  margin: 0;
  font-size: 1rem;
  color: #f0c105;
}

.tabContent > ul > li span {
  font-size: 0.75rem;
}

.multiButtonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 0.4rem;
  flex: 1;
}

.smallButton {
  font-size: 0.6rem;
  font-weight: 600;
  text-align: center;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
}

.errorText {
  background-color: #c63943;
  padding: 0.05rem;
}
