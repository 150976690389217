/**
 * GameSelectList.css
 *
 * This css is used by GameSelectList.tsx to style custom the static(rules) adn dynamic(game) list
 */

.gameTypeDescription {
  color: #efc005;
  font-weight: bold;
  margin: 0 3em;
  top: 2px;
  position: relative;
}

.gameTypeTitle {
  color: #efc005;
  margin-bottom: 0.75em;
  font-size: 1.2em;
  font-weight: bold;
  text-align: center;
}

.gameTypeListColumn {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-bottom: 4px;

  /* Modal / Section */
  background: rgba(2, 2, 11, 0.5);
  border-radius: 16px;

  /* Inside auto layout */
  margin: 0px 4px;

  width: 100%;
}

.gameCategoryLabel {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.4rem 0;
  max-height: 2em;
  box-sizing: border-box;

  /* Game Type / Classic */
  background: #1a9e27;
  border-radius: 8px 8px 0px 0px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;

  font-style: normal;
  font-weight: bold;
  font-size: 0.8rem;

  white-space: pre;

  /* identical to box height */
  text-align: center;

  /* Text/Light */
  color: #fbfbff;

  margin-bottom: 4px;
}

.gameSelectorGames {
  display: flex;
  overflow-x: auto;
  text-align: center;
  flex: 1;
  height: 100%;
}
