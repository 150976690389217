/**
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * This file specifically styles a reusable "Card" component. It is an "Element" component so it is devoid of business
 * logic. As such, the suit, the rank, the face-up card, the face-down card, and the animations for the card are all
 * defined here.
*/

.card {
  --size: 1.8;
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: calc(0.65rem * var(--size));

  appearance: none;
  user-select: none;

  margin: 0.1rem;
  padding: 0;

  display: inline-block;

  border: none;
  color: #2c2c2c;
  background-color: #ffffff;

  width: calc(2rem * var(--size));
  height: calc(2.85rem * var(--size));
  border-radius: calc(4px * var(--size));

  position: relative;
  transform: scale(1) translateY(0px);

  animation: lower 1s ease-in-out;
  transition: all 0.6s ease-in-out;
}

:global(.folded) .card {
  top: 0;
  filter: brightness(45%);
  animation: none;
}

.faceDown {
  background-image: url(/src/static/graphics/BlueBack.svg);
  background-size: contain;
  background-position: center;
}

.cardContent {
  opacity: 0.85;

  display: flex;
  width: 100%;
  height: 100%;

  padding: 0.1em 0.1em 0.2em 0.15em;
  box-sizing: border-box;
}

.rank {
  letter-spacing: -0.1em;
  transform: translateX(-10%);

  font-size: 1.2em;
  font-weight: 600;
  line-height: 1;

  position: relative;
}

.heart .rank,
.diamond .rank {
  color: #d7263d;
}

.suit {
  display: block;
  padding-top: 100%; /* Set height = width, because padding-top uses width %?? css is weird */
  width: 100%;
  height: 0;
  background-repeat: no-repeat;
}

.left .suit {
  width: 1em;
}

.left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  flex: 1;
  box-sizing: border-box;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.club .suit {
  background-image: url(/src/static/graphics/suits/club.svg);
}
.diamond .suit {
  background-image: url(/src/static/graphics/suits/diamond.svg);
}
.heart .suit {
  background-image: url(/src/static/graphics/suits/heart.svg);
}
.spade .suit {
  background-image: url(/src/static/graphics/suits/spade.svg);
}

@keyframes lower {
  0% {
    transform: translateY(-20%);
  }
  100% {
    transform: translateY(0);
  }
}
