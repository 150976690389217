#joinButton {
  margin-left: auto;
  display: block;
}

.inner-container {
  background-color: rgba(0, 0, 0, 0.296);
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  font-size: 1rem;
  margin: auto;
  width: 100%;
  height: 100%;
}

.logo {
  background-image: url(/src/static/graphics/Logo35Percent.svg);
  background-position: center;
  background-size: 30%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
  background-repeat: no-repeat;
}

.topbar {
  display: flex;
  justify-content: flex-end;
}

.button {
  background-color: #44c767;
  width: 10em;
  text-align: center;
  border-radius: 0.375rem;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 17px;
  font-weight: bolder;
  padding: 16px 31px;
  text-decoration: none;
  text-shadow: 0px 1px 0px #2f6627;
}
.button:hover {
  background-color: #38855c;
}
.button:active {
  position: relative;
  top: 1px;
}

h1 {
  font-size: 3em;
}
.hero-section {
  margin-top: 5em;
  padding: 0 10%;
  color: white;
  display: flex;
  flex: 5;
  flex-direction: column;
}

.footer {
  padding: 0.5em 10%;
  background-color: black;
  bottom: auto;
  display: flex;
}

.step {
  flex: 1;
  color: #ffffff;
  font-weight: 900;
  text-align: center;
  align-self: center;
}
