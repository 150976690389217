.admit-overlay {
  z-index: 20;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
.admit-overlay-text {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0.5em;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  color: white;
  font-weight: 700;
  z-index: 3;
}
.admit-overlay-text.mobile {
  height: 90%;
  justify-content: center;
}

.admit-player-name {
  text-align: center;
  font-size: 1.5em;
  word-break: break-all;
}

.admit-overlay-buttons {
  display: flex;
  flex-direction: row;
  width: 90%;
  bottom: 0.5em;
  height: 20%;
  position: absolute;
  justify-content: center;
  z-index: 4;
  gap: 0.3em;
}

.admit-buttons-container {
  display: flex;
  width: 100%;
  justify-content: center;
}

.admit-decline-button {
  width: 100%;
  display: flex;
  flex-direction: row;
}
