/**
 * Settings.css
 *
 * This is a css mostly around the sibling Settings.tsx and all the sub-components around it
 */
.section {
  border-radius: 12px;
  display: flex;
  background-color: #02020b80;
  padding: 0.35rem 0.5rem;
  justify-content: space-between;
  font-weight: 600;
}

.rulePage {
  flex: 1;
  display: flex;
}

.rulePage::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

.rulePage::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: rgb(39, 45, 45);
}

.rulePage::-webkit-scrollbar-thumb {
  border-radius: 12px;
  background-color: rgb(128, 128, 132);
  margin-right: 8px;

  background-clip: content-box;
}

.rulesModalContentContainer .section {
  padding: 0.3em 0.8em;
}

.rulePage .sectionContent {
  flex: 1;
}

.sectionTextContent {
  display: flex;
  flex-direction: column;
  max-width: 98%;
  gap: 0.3em;
}

.sectionTextTitle {
  color: #f0c105;
  font-weight: 700;
  text-align: left;
  font-size: 1.2em;
}

.sectionBulletPoints {
  line-height: 1.6;
  font-size: 1em;
}

.objective {
  color: #ffffff;
  font-size: 1em;
  font-weight: 700;
  text-align: left;
  margin: 0.15em 0;
}

.objectiveText {
  line-height: 1.6;
  font-weight: 600;
  color: #d5d5d7;
}

.actionText {
  line-height: 1.6;
  font-size: 1em;
  text-align: left;
  max-width: 52em;
}

.dealTitle {
  text-align: left;
  font-weight: 700;
}

.ruleNoteTitle {
  margin-top: 1em;
  text-align: left;
  font-weight: bold;
}

.ruleNotes {
  text-align: left;
}

.ruleNotes ul {
  margin-top: 0;
  padding-inline-start: 1.2em;
}

.optionsCaption {
  color: #f0c105;
  font-weight: bold;
  font-size: 1em;
  margin-top: 0.5em;
}

.optionTitle {
  font-size: 1em;
  display: flex;
  align-items: center;
  align-self: center;
  font-weight: 700;
  color: #ffffff;
  white-space: nowrap;
}

.optionTitle > span {
  /**
   * 6.4em is just the least common denominator which yields aligned radio inputs.
   * The best way for us to align the row headers ("Version", "Winner(s)", "Option", etc.)
   * would be to use CSS grid and have a fixed 1st column width with row gap.
   *
   * That being said, this is probably fine as long as we adjust it when section titles change.
   */
  width: 6.4em;
  color: #f0c105;
  text-align: left;
}

.optionSelectionText {
  font-weight: 600;
}

.optionSelectionTitle {
  font-weight: 700;
  color: #ffffff;
}

.optionSelectionDescription {
  font-size: 16px;
  color: #d5d5d7;
  font-weight: 600;
}

.optionDescription {
  font-weight: 700;
  color: #686c6d;
}

.optionDescription.selected {
  font-weight: 700;
  color: #fff;
}

.radioInput {
  display: none;
}

.radioInput:checked + .customRadio::after {
  transform: scale(1);
}

.customRadio {
  width: 1em;
  height: 1em;
  min-width: 1em;
  border: 2px solid;
  border-radius: 50%;
  margin-right: 0.3em;
  box-sizing: border-box;
  padding: 2px;
}

.sectionImages {
  height: 5.2em;
  display: flex;
  gap: 1em;
}

input[type="radio"] * {
  margin-left: 9px;
  background-color: #f0c105;
  color: #f0c105;
  width: 22px;
  height: 22px;
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.customRadio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.customRadio::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: #f0c105;
  border-radius: 50%;

  transform: scale(0);
}

.versionOptionTitle {
  align-items: center;
}

.gameFlavorOption {
  font-size: 1.2em;
  text-align: left;
  margin: 0.5em 2em;
  margin-left: 0;
}

.optionSelectionContainer {
  color: #d5d5d7;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: flex-start;
  width: 100%;
  margin-left: 0.5em;
}
.optionSelectionContainer.overflow {
  overflow: auto;
  flex-wrap: nowrap;
}

.radioOptionText {
  display: grid;
  font-weight: 700;
}

.winnersOption {
  display: flex;
  font-size: 1em;
  margin-right: 0.5em;
  padding: 6px 8px;
}

.winnersRuleOption {
  display: flex;
  font-size: 1em;
  margin-right: 0.5em;
  padding: 6px 8px;
}

.optionsSubSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.gameSetting {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: auto;
}

.gameSetting .section {
  margin-top: 0.5rem;
}

.gameRules {
  overflow: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.gameRules .section {
  height: 100%;
  overflow: auto;
}

.rulesOptionsContainer {
  overflow: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.RulesDocumentation .gameSetupButtonsContainer {
  margin: 1em 0;
  justify-content: space-between;
}
.rulesTab {
  height: 1rem;
  font-size: 1rem;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.actionButton.activeRulesButton {
  border: 1px solid #f0c105;
  background-color: #f0c105;
  color: #414148;
}

.actionButton.activeHelpButton {
  border: 1px solid #f0c105;
  background-color: #f0c105;
  color: #414148;
}

.actionButton.backToAllGamesButton {
  width: max-content;
  margin: 0.8em 0em 0em 0.8em;
}

/* container for game selection */
.gameSetupButtonsContainer {
  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
  min-height: 3.5rem;
  max-height: 3.5rem;
  align-items: center;
}

/* container for non-host rules */
.hostOptionRulesButtons {
  width: 16em;
  align-self: center;
  margin: unset;
}

.activeButton {
  display: flex;
  flex: 8;
  border: 1px solid #f0c105;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bolder;
  background-color: #f0c105;
  color: #414148;
  cursor: pointer;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.inactiveButton {
  display: flex;
  flex: 8;
  border: 1px solid #fff;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bolder;
  background-color: #272e29;
  cursor: pointer;
  color: white;
  text-align: center;
  align-content: center;
  justify-content: center;
}

.disabledButton {
  pointer-events: none;
  cursor: default;
  opacity: 0.35;
}

.optionsSection {
  border-radius: 16px;
  margin-top: 0.5em;
  display: flex;
  background-color: #02020b80;
  padding: 1rem;
  justify-content: space-between;
}

.rulesTitle {
  font-weight: bolder;
  float: left;
}

.rulesText {
  line-height: 1.6;
  font-size: 16px;
}

.actionTitle {
  font-weight: bolder;
  float: left;
}

.actionItems {
  line-height: 1.6;
  font-size: 16px;
  text-align: left;
}

.objectSection {
  font-weight: bolder;
  float: left;
}

.rulesModalContentContainer {
  flex: 1;
  overflow: auto;
}

.rulesSection {
  background-color: #02020b80;
  border-radius: 15px;
  overflow: auto;
  height: 100%;
}

.rulesModalContentContainer.rules3 {
  display: flex;
  align-items: center;
  width: 100%;
}

.hostControlsModalContentContainer {
  background-color: #02020b80;
  border-radius: 15px;
  padding: 1em 1em;
  height: 100%;
  overflow: auto;
  display: flex;
}

.modal ::-webkit-scrollbar {
  width: 24px;
}
.modal ::-webkit-scrollbar-track {
  border-radius: 13px;
  background-color: rgba(255, 255, 255, 0.1);
  margin-right: 8px;
  border: 8px solid transparent;
  background-clip: content-box;
  overflow: hidden;
}
.modal ::-webkit-scrollbar-thumb {
  border: 8px solid transparent;
  background-color: rgba(255, 255, 255, 0.4);
  background-clip: content-box;
  border-radius: 13px;
}
.rulesModalContentContainer .gameTypeListColumn {
  width: unset;
  flex: 1;
}
.rulesModalContentContainer .gameSelectEntry {
  color: #ccced0;
}
.rulesModalContentContainer .gameSelectEntry:hover {
  color: #272d2d;
}
.rulesModalContentContainer .gameSelectEntry:hover .gameTypeRibbon {
  background-color: #272d2d;
  color: #f0c105;
}

.tableRules {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  background-color: transparent;
}

.tableRules .tableHeader {
  background-color: #1a201e;
  position: sticky;
  padding: 1em;
  top: 0em;
  z-index: 1;
}

.tableRules .tableContent {
  padding: 0em 1em 1em 1em;
}

.tableRules .lowMethodLabel {
  color: #686c6d;
}

.tableRules .lowMethodBox {
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.rulesRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.rulesRow.section {
  border: 2px solid #808085;
  padding: 0rem 1rem;
  margin-bottom: 0.4em;
}

.flexRulesTitle {
  display: flex;
  flex-direction: column;
}
.flexRulesTitle .title {
  text-align: start;
  align-self: flex-start !important;
  display: flex;
  align-items: center;
}
.flexRulesTitle .description {
  font-size: 0.8em;
  text-align: start;
}

.rulesRow:first-child .leftColumn,
.rulesRow:first-child .rightColumn {
  border-top: 1px solid rgb(255, 255, 255, 0.57);
}
.leftColumn {
  display: flex;
  border-top: none;
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
  border-right: 1px solid rgb(255, 255, 255, 0.57);

  flex: 1;
  padding: 0.5rem 0.75rem;
  padding-left: 0;

  font-weight: 600;
  color: #d5d5d7;
  align-items: center;
}

.rightColumn {
  color: #fff;
  border-top: none;
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
  border-left: none;

  display: flex;
  flex: 1;
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  line-height: 1.5;
  align-items: center;
}

.rulesRow.yellowBorder .leftColumn,
.rulesRow.yellowBorder .rightColumn {
  border-color: #f0c105;
}

.masterStartupContainer .rightColumn,
.masterStartupContainer .leftColumn {
  border-top: none !important;
  border-bottom: none;
}

.tableSetting .rightColumn,
.tableSetting .leftColumn {
  border-top: none !important;
  border-bottom: none;
}

.boldTitle {
  font-weight: 700;
  color: #fff;
  display: flex;
  align-items: center;
}

.smallContent {
  flex: 1.65;
}

.largeContent {
  flex: 2;
}

.rulesModalContentContainer .boldTitle {
  color: #f0c105;
}

/* banking ledger table styling */
.bankingLedgerModalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8em;
}

.ledgerTable {
  flex: 1;
  width: 100%;
  overflow: auto;
}

.bankingLedgerModalFooter {
  height: 10.5em;
}

.ledgerRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ledgerRow > div {
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
  border-right: 1px solid rgb(255, 255, 255, 0.57);

  flex: 1;
  padding: 0.5em 1em;

  font-weight: 600;
  color: #fff;
}
.ledgerRow > div:first-child {
  color: #d5d5d7;
}
.ledgerRow > div:last-child {
  border-right: none;
}
.ledgerRow .ledgerEmpty {
  border-right: 1px solid transparent;
}
.ledgerRow .ledgerMergedRow {
  flex: 1 2 50%;
  text-align: end;
}
.ledgerSubtext {
  font-size: 0.8em;
}
.ledgerRow .ledgerPlayerName:hover {
  background: #f0c105;
  color: #272d2d;
  cursor: pointer;
}

.col {
  display: flex;
  font-weight: 700;
  padding: 1em;
  color: white;
  border-right: 1px solid rgb(255, 255, 255, 0.57);
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
  flex: 1;
}

.chipTransactionText {
  font-weight: 600;
  color: #fff;
  font-size: 1.25rem;
}

.chipTransaction {
  border: 2px solid #494b4d;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1;
  padding: 0.625em 1.25em;
  gap: 0.75em;
}

.chipTransaction .chipButtons {
  display: flex;
  gap: 0.75em;
}

.chipTransaction .chipButtons button {
  padding: 0.625em;
  font-size: 1em;
  line-height: 1.4;
}

.chipTransaction .unrestrictedChip {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.unrestrictedChip .tooltipButtonContainer {
  width: 1rem;
  height: 1rem;
}

.unrestrictedChip label {
  color: #d5d5d7;
  font-weight: 600;
}

.chipTransferArea {
  display: flex;
  flex: 1;
  height: 11em;
  border: 1px solid;
  border-radius: 8px;
}

.chipTransferContainer,
.chipSellContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  gap: 0.85em;
  border: 2px solid #f0c105;
  border-radius: 8px;
  padding: 0.75em 1em;
  position: relative;
}

.chipTransferHeader,
.chipSellHeader {
  color: white;
  font-size: 1.25em;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.chipTransferHeader .close,
.chipSellHeader .close {
  position: absolute;
  right: 0.75em;
  font-size: 1.25em;
  cursor: pointer;
}

.chipTransfer,
.chipSell {
  font-size: 1em;
  color: white;
  display: flex;
  gap: 0.75em;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.chipTransferContainer button,
.chipSellContainer button {
  padding: 0.75rem 1rem;
  margin: 0 2.5em;
}

.playerSelect {
  width: 11em;
  padding: 0.75em;
  font-weight: bold;
  border-radius: 8px;
  color: gray;
  font-size: 0.75em;
}

/* Table Settings */
.betReadingsContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.tableFootnote {
  color: white;
  font-weight: bold;
  font-style: italic;
  text-align: right;
  padding-top: 20px;
  padding-bottom: 25px;
}

.ante {
  display: flex;
  flex-direction: column;
}

.betReadingsContainer .ante .tooltipButtonContainer {
  padding: 0 2px;
}

.minBet {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.minBet [data-reach-listbox-button] {
  border-width: 0px;
}
.minBet .chipContainer {
  cursor: pointer;
}

.maxBet {
  display: flex;
  flex-direction: column;
}

.chipDenomination .minBet {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.chipDenomination .dropdownButton {
  border-radius: 4px;
  height: 1.3em;
  width: 1.3em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chipDenomination .dropdownButton [data-reach-listbox-arrow] {
  margin-left: 0;
}

.chipDenomination .dropdownWrapper .dropdownPopover {
  margin-top: 4px;
  z-index: 2;
  max-height: 10em;
  overflow: auto;
}

.chipDenomination .dropdownWrapper [data-reach-listbox-list] {
  font-size: 0.75em;
}

.chipDenomination .dropdownWrapper .dropdownPopover::-webkit-scrollbar-thumb,
.customDropdown .dropdownWrapper .dropdownPopover::-webkit-scrollbar-thumb {
  background-color: #d5d5d7;
}

.bettingStructureInput {
  border-radius: 5px;
  width: 7em;
  height: 2em;
}

.title {
  align-self: center;
  display: flex;
  align-items: center;
}

.noLimitContent {
  display: flex;
  justify-content: space-between;
  flex: 1 1;
}

.gameName {
  margin: 0.4em 0;
}

/* House Rules */

.houseRulesTextArea {
  color: white;
  margin-bottom: 0.5rem;
  line-height: 1.5;
}

.houseRulesTextArea + .rulesRow .leftColumn,
.houseRulesTextArea + .rulesRow .rightColumn {
  border-top: 1px solid rgb(255, 255, 255, 0.57);
}

.houseRulesTextAreaTitle {
  font-weight: 700;
}

.houseRulesTextArea ol {
  padding-inline-start: 1.2rem;
}

.exitModalButton {
  flex: 1;
  padding: 0.5em;
  background-image: url(/src/static/graphics/exitModalButton.svg);
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}

.left .buttonsBottom .modalBottomButton .actionButton.blue {
  width: fit-content;
  align-self: center;
}

.keyword {
  font-weight: bolder;
}

.modalTitle {
  color: white;
  font-size: 2em;
  font-weight: bold;
  align-self: center;
  justify-self: center;
  margin: 0.2em;
}

/* Master Startup */

.masterContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.masterStartupContainer {
  flex: 1 1;
  flex-direction: column;
  justify-content: stretch;
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  text-align: center;
  overflow: auto;
}

.tableRulesWrapper {
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  padding: 0em;
  height: 100%;
  justify-content: flex-start;
}

.startupSubHeader {
  color: #f0c105;
  font-weight: 700;
  font-size: 1.5em;
  margin-bottom: 0.4em;
  white-space: nowrap;
}

.bettingSubHeader {
  color: #f0c105;
  font-weight: 700;
  font-size: 1.2em;
  padding: 0.4em;
  border-bottom: 1px solid rgb(255, 255, 255, 0.57);
}

.tableSettingsContentContainer {
  background-color: rgba(2, 2, 11, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.table {
  width: 100%;
  text-align: center;
  padding-top: 0.6em;
}

.dropdownContainer {
  display: flex;
  flex: 1;
}
.dropdownContainer > div,
.chipDenomSetupContainer > div {
  flex: 1;
  padding: 0 0.5rem;
}

.dropdownCaption {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.denominationSelect {
  width: 100%;
  padding: 0.5em;
  border-radius: 0.4em;
  font-weight: bold;
}
.chipDenomSetupContainer {
  flex: 1;
  display: flex;
}
.chipDenomSetupContainer .chipContainer {
  /* line-height: 5.2em; */
  font-size: 1.25em;
  grid-gap: 3em;
}
.inactiveButton {
  border: 1px solid #fff;
  border-radius: 5px;
  background-color: #272e29;
  color: #fff;
}
.activeButton,
.inactiveButton {
  flex: 8 1;
  padding: 0.5em 0.5em;
  font-weight: bolder;
  cursor: pointer;
  text-align: center;
  line-height: 1em;
}

.tableRules ul {
  margin: 0;
}

.lobbyContainer {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  grid-gap: 1em;
  text-align: center;
  justify-content: space-between;
}

.lobbyTopContainer {
  display: flex;
  flex-direction: column;
}
.lobbyText {
  color: #ffffff;
  font-weight: 700;
  font-size: 1.2em;
}
.lobbyText.colored {
  color: #f0c105;
}
.lobbyText .biggerText {
  font-size: 1.8em;
}
.capitalize {
  text-transform: capitalize;
}
.lobbyMobileAdmitContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.lobbyMobileComponent {
  width: 5em;
}
.lobbyMobileAdmitPlayerContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 60%;
}

.lobbyMiddleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
  background: #272d2d;
  border-radius: 10px;
  padding: 1.5em 1em;
  width: 96%;
}
.lobbyMiddleContainer.borderColored {
  margin-top: 0.5em;
  border: 3px solid #f0c105;
}

.lobbyTableCodeTitle {
  color: #f0c105;
  font-weight: 700;
  font-size: 1.2em;
}
.lobbyCode {
  cursor: pointer;
  background: #018cda;
  color: white;
  border: none;
  font-family: "Open Sans";
  font-size: 2em;
  font-weight: 700;
  width: 6em;
  text-align: center;
  border-radius: 6px;
  user-select: none;
  cursor: pointer;
}
.lobbyCopyButton {
  border-radius: 8px;
  background: #018cda;
  border: 2px solid rgba(255, 255, 255, 0.5);
  font-weight: 700;
  font-size: 1.1em;
  color: white;
  width: fit-content;
  padding: 0.5em 3.2em;
  cursor: pointer;
}
.lobbyCopyButtonNotification {
  border-radius: 8px;
  background: transparent;
  border: 2px solid #f0c105;
  font-weight: 700;
  font-size: 1.1em;
  color: #f0c105;
  width: fit-content;
  padding: 0.5em 3.2em;
  cursor: pointer;
}
.lobbyBottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7em;
}
.lobbyBottomButton.actionButton {
  display: flex;
  font-size: 1.2em;
  font-weight: 700;
  padding: 0.5em 1em;
  width: fit-content;
  border-radius: 8px;
}

.startMenuContainer {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding-bottom: 1em;
  text-align: center;
}
.startMenuSubtitle {
  color: #f0c105;
  font-size: 1.2em;
  font-weight: 700;
}
.startMenuSubcontainer {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}
.startMenuButtonRow {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  padding: 0em 1.5em;
}
.startMenuButtons.actionButton {
  display: flex;
  flex: 1 1;
  font-size: 2em;
  font-weight: 700;
  padding: 0.3em 0.6em;
  border-radius: 8px;
}
.startMenuButtons.practiceButton.actionButton:hover {
  background: #f0c105;
  color: #272d2d;
  border-color: #ffffff80;
}
.startMenuDescriptionRow {
  display: flex;
  flex-direction: row;
}
.startMenuTextContainer {
  flex: 1 1;
}
.startMenuDescriptionText {
  color: #d5d5d7;
  font-size: 1em;
  font-weight: 700;
}
.startMenuText {
  color: white;
  font-size: 1em;
  font-weight: 700;
}

/* host controls cashout modal*/
.hostControlsCashoutContainer {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  gap: 0.9em;
}
.hostControlsCashoutHeader {
  font-weight: 700;
  font-size: 2em;
  color: white;
}
.hostControlsCashoutSubtext {
  font-size: 1em;
  color: white;
}
.hostControlsCashoutDetail {
  font-size: 0.9em;
  font-weight: 700;
  color: white;
}
.hostControlsCashoutFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5em;
}

.verticalTimer {
  font-size: 1.5em;
}

.timerNumbers {
  font-size: 4em;
  color: white;
  font-weight: 700;
}
.hostControlsCashoutFooter .timerNumbers {
  font-size: 1.7em;
  padding: 0 0.2em;
}

.minutesText {
  font-size: 0.75em;
  color: white;
  font-weight: 600;
}
.wildCardOptions .radioOptionText {
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
}

.wildCardOptions {
  font-weight: normal;
}

.wildCardOptionIcon {
  font-size: 0.8em;
  margin-left: 0.08em;
}

.versionSectionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.versionSectionOptions {
  display: flex;
  flex-direction: column;
  max-width: 70%;
}

.currentGameText {
  color: #f0c105;
  font-size: 1.1rem;
}

.preventSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

@media only screen and (min-width: 960px) {
  .dropdownContainer.initialBuyInCustomFlex {
    flex: 0 0 33.33% !important;
  }
  .dropdownContainer.noLimitCustomFlex {
    flex: 0 0 66.66% !important;
  }
  .noLimitContent {
    flex: 0 0 60% !important;
  }
}

.helpPage {
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 100%;
  justify-content: space-between;
  text-align: center;
}

.technicalProblem {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.helpTitle {
  color: #f0c105;
  font-weight: bold;
  font-size: 2rem;
  margin-bottom: 8px;
}

.refreshBrowserButton,
.submitIssueButton {
  font-size: 1.5em;
  background: #1a9e27;
  border: 1px solid #fff;
  font-weight: bold;
  color: #fff;
  padding: 1em;
  border-radius: 8px;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 10px;
  align-self: center;
  width: fit-content;
}

.helpPoint {
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.reportIssue {
  width: 100%;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}

.reportIssueTextarea {
  font-size: 1rem;
  height: 50px;
  align-self: center;
  border-radius: 8px;
  padding: 0.5rem;
  width: 60%;
  max-width: 90%;
}

.selected {
  font-weight: 700;
  color: #fff;
}

.checkbox,
.radiobox {
  display: flex;
  align-items: center;
  gap: 0.2em;
}

.checkbox input,
.radiobox input {
  appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 3px 5px;
}

.checkbox input {
  background-image: url(/src/static/graphics/Box.svg);
}

.checkbox input:checked {
  background-image: url(/src/static/graphics/CheckedBox.svg);
}

.radiobox input {
  background-image: url(/src/static/graphics/RadioUnchecked.svg);
}

.radiobox input:checked {
  background-image: url(/src/static/graphics/RadioChecked.svg);
}

.chipInfo {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #ffffff;
  box-sizing: border-box;
  width: 100%;
  background-color: #272d2d;
  font-size: 1.2rem;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.4;
  height: 100%;
}

.chip-alert {
  border: 4px solid #d7263d;
}

.chip-notify {
  border: 4px solid #f0c105;
}

.fieldGroup {
  background: #fff;
  color: #414148;
  display: flex;
  align-items: center;
  width: 9em;
  border-radius: 8px;
}

.fieldGroup .prefix {
  font-size: 0.9em;
  font-weight: 700;
  padding: 0 0.5em;
}

.fieldGroup .field {
  border: none;
  padding: 0.55em 0.3rem;
  border-radius: 8px;
  font-size: 0.9em;
  font-weight: 700;
  width: 100%;
  color: #414148;
}

.fieldGroup.danger {
  border: 2px solid #d7263d;
  color: #d7263d;
}

.fieldGroup.danger input,
.fieldGroup.danger select {
  color: #d7263d;
}

.fieldGroup input:focus-visible,
.fieldGroup select:focus-visible {
  outline: 0;
}

.chipBottom {
  display: flex;
  padding: 0 0.5em;
  position: relative;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.chipBottom .alert {
  position: absolute;
  left: 0;
  max-width: 16em;
  min-height: 2.2em;
  display: flex;
  align-items: center;
}

.alert {
  background: #fff;
  padding: 2px 8px;
  font-size: 0.8em;
  align-self: center;
  flex: 1;
  font-weight: 700;
  color: #000;
}

.alert.danger {
  background: rgba(181, 60, 60, 0.35);
  border: 2px solid #b53c3c;
  color: #fff;
}

.bankingLedgerModalContainer .chipNotification {
  font-size: 1.5em;
}

.bankingLedgerModalContainer .chipNotification .title {
  font-size: 1.5em;
}

.rowCaption {
  color: #f0c105;
  font-weight: bold;
  font-size: 1em;
}

.blindSetup {
  display: flex;
  flex-direction: column;
}

.blindSetup .title {
  display: flex;
  align-items: center;
}

.tableSettingRules {
  height: auto;
  padding: 0.5em 0;
}

.tableSettingRules .rightColumn,
.tableSettingRules .leftColumn {
  border-top: none !important;
  border-bottom: none;
}

.flexColumn {
  display: flex;
  flex-direction: column !important;
  text-align: center;
}

.flexColumn .caption {
  display: flex;
  align-items: center;
}

.flexColumn .caption > span {
  padding-left: 2px;
}

.flexColumn .value {
  font-size: 1.25em;
}

@media only screen and (max-width: 768px) {
  .dropdownContainer {
    flex-direction: column;
    gap: 0.3em;
  }
  .noLimitContent,
  .betReadingsContainer,
  .chipDenomSetupContainer,
  .rightColumn .chipAreaRow {
    flex-direction: column;
    align-items: center;
    gap: 0.3em;
  }
  .gameRules,
  .activeButton,
  .inactiveButton {
    font-size: 0.7em;
  }
}

.lowMethodOptions {
  display: flex;
  flex-direction: column;
  gap: 0.15em;
}

.lowMethodLabel {
  font-weight: 600;
  font-size: 0.95rem;
  color: #d5d5d7;
}

.lowMethodLabel.selected {
  color: #fff;
}

.lowMethodTooltip {
  margin-top: 0.05em !important;
}

.accountPage .JoinGameWidget {
  background-color: transparent;
  padding: 0;
  border: none;
}

.accountPage .JoinGameButton {
  background-color: #b53c3c;
  margin-top: 15px;
  margin-left: 0;
}

.accountPage .JoinGameWidgetInput {
  height: 2.35em;
  width: 100%;
  font-size: 0.8em;
  padding: 1.25em 0;
}

.accountPage .JoinGameWidgetErrorContainer {
  width: 100%;
}

.lowHandSuggestion {
  padding-inline-start: 1.2rem;
}

.flexRowCenter {
  display: flex;
  align-items: center;
}

.customDropdown .dropdownWrapper {
  font-size: 0.75em;
  width: 100%;
}

.customDropdown .dropdownButton {
  width: 100%;
  background: #fff;
  color: #000;
  border-radius: 0.45em;
  padding: 0.25rem 0.3rem !important;
  font-weight: 600;
  border: 2px solid #d5d5d7 !important;
  cursor: pointer !important;
}

.customDropdown .dropdownPopover {
  margin-top: 13.5rem;
  z-index: 2;
  max-height: 12.5em;
  overflow: auto;
}

.customDropdown .dropdownPopover.raisedPopover {
  margin-top: -19rem;
}

.customDropdown .dropdownPopover [data-reach-listbox-list] {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.customDropdown [data-reach-listbox-arrow] svg {
  margin-top: 0.75em;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.freeTrialInfo {
  background-color: #02020b;
  color: #f0c105;
  width: 100%;
  font-size: 1.1em;
  margin-bottom: 10px;
  border-radius: 0.5em;
  padding-top: 4px;
  padding-bottom: 4px;
  font-weight: bold;
}

.whiteFont {
  color: #ffffff;
}

.normalFont {
  font-size: 1em !important;
  margin-left: auto;
  margin-right: auto;
}

.alert {
  background-color: #b53c3c;
  color: #ffffff;
}
