/*
  Style module for PaymentPlanSelect screen
*/
.modalSubheader {
  font-size: 1.2rem;
  font-weight: bold;
  color: #f0c105;
  margin-bottom: 0.75rem;
}
.exitModalButton {
  right: 0.8rem;
  top: 0.8rem;
  height: 2rem;
  width: 2rem;
  background-color: transparent;
  border: none;
}
.paymentOptionsContainer {
  flex-direction: row;
  display: flex;

  gap: 0.75em;
}
.paymentOption {
  background-color: #151818;
  flex: 1;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
}
.optionBanner {
  color: #fff;
  background-color: #6e6c72;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 0.4rem 0.4rem 0 0;
  padding: 0.4rem;
  font-size: 0.9rem;
}
.optionBanner.blueBg {
  background-color: #3a8de0;
}
.optionBanner.magentaBg {
  background-color: #a52acb;
}
.optionContent {
  color: #d5d5d7;
  padding: 0.5rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.optionHeader {
  padding: 0.4rem;
  margin-bottom: 0.5rem;
}
.optionName {
  color: #fff;
  font-size: 1.9rem;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 0.4rem;
}
.optionType {
  font-size: 0.75rem;
  color: #f0c105;
  font-weight: bold;
  text-transform: capitalize;
}
.optionPoints {
  text-align: left;
  min-height: 6rem;
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 1.2rem;
  padding: 0;
  margin: 0;
}
.optionPoint {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-bottom: 0.4rem;
}
.optionPointCheck {
  margin-left: 0.2rem;
}
.optionPointText {
}
.optionPrice {
  font-weight: bold;
  font-size: 0.9rem;
}
.optionPriceAmount {
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
}
.optionPricePeriod {
  margin-left: 0.25rem;
}
.optionPriceNote {
  font-size: 0.75rem;
}
.optionBillingPeriod {
  color: #f0c105;
  font-weight: bold;
  margin: 1rem 0;
  margin-top: 0.3rem;
}
.optionButton {
  font-size: 1.3rem;
}
.paymentOptionsFootnote {
  color: #d5d5d7;
  font-weight: bold;
  font-size: 0.9rem;
  list-style: none;
  padding: 0;
  margin: 0;
}
