/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for "HandValueItem", a component that renders the hand's low or high value with a corresponding icon
 * inside of a dark box.
 */

.handValueItem {
  --handValueIconSize: 1.15rem;

  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  padding: 0.3rem;
  border-radius: 5px;
  background: #272d2d;
  color: whitesmoke;
  font-size: 0.9rem;
  transition: opacity 1s;

  /**
  Note: we added this as duct-tape to prevent the hand value item from rendering _below_ any cards and arrow buttons, as the turnIndictorContainer has z-index 21
  */
  z-index: 22;
}

.handValueItem:empty {
  height: 1.6rem;
}

.valueIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--handValueIconSize);
  width: var(--handValueIconSize);
  border-radius: 50%; /* to make it a circle */
  margin: 0 0.1rem;
}

.lowValueIcon {
  background-color: #0e77d0;
}

.highValueIcon {
  background-color: #1c901e;
}

.description {
  flex: 1;
  text-align: center;
  margin: 0 0.2rem;
}

.eyeballActionUI {
  appearance: none;
  background: none;
  border: none;
  color: #808085;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: var(--handValueIconSize);
  height: var(--handValueIconSize);
}

.eyeballActionUI > svg {
  width: 20px;
}

.eyeballActionUI:hover,
.eyeballActionUI[aria-pressed="true"] {
  color: #ffffff;
  cursor: pointer;
}
