/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * This file specifically styles "Winnings". It is a component that renders the amount of money
 * a winner of a game has won.
 */

.winnings {
  padding: 0.1em 0.4em;
  border-radius: 0.5em;
  color: white;
  font-weight: bold;
  height: fit-content;
  width: fit-content;
  position: absolute;
  z-index: 21;

  right: 0.2em;
  bottom: 1.5em;

  box-shadow: black 1px 1px 4px 1px;
}

.sidePotWinningsContainer {
  position: absolute;
  z-index: 21;
  right: 0.2em;
  bottom: 1.5em;
}
.sidePotWinnings {
  padding: 0.1em 0.4em;
  border-radius: 0.5em;
  color: white;
  font-weight: bold;
  height: fit-content;
  width: fit-content;
  box-shadow: black 1px 1px 4px 1px;
  margin-bottom: 2px;
}

:global(.actionArea) .sidePotWinningsContainer {
  right: -4.5rem;
}

:global(.right) .winnings,
:global(.right) .sidePotWinningsContainer {
  left: 0.2em;
}

.wonHigh {
  background: rgba(26, 158, 39, 1);
}

.wonLow {
  background: rgba(0, 163, 255, 1);
}

.wonBoth {
  background: rgba(240, 193, 5, 1);
}

.lose {
  background: rgb(181, 60, 60);
}
