.experimentalModalContent {
  flex-direction: column;
  color: #e9e9e9;
}
.experimentalModalSubtitle {
  color: #e9e9e9;
  text-align: center;
}
.contentWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.pointer {
  cursor: pointer;
}
.selectDescription {
  user-select: none;
}
