/**
 * HostControls.css
 *
 * This is a css mostly around the sibling HostControls and all the sub-modal around it
 */
.navigateBack {
  color: #fff;
  cursor: pointer;
  font-size: 3em;
  position: absolute;
}
.hostControlsTableWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.hostControlsTableCode {
  position: absolute;
  top: 0.5em;
  left: 1em;
  background-color: #02020b;
  font-weight: bold;
  padding: 0.1rem 1.2rem;
  text-align: center;
  border-radius: 0.25em;
}

.hostControlsHeaderOptions {
  position: absolute;
  top: 0.35em;
  right: 3.8em;
}

.hostControlsHeaderOptions .help {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.55em 0.9em;
  background: #02020b;
  border: 2px solid #808085;
  border-radius: 8px;
  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
}

.help .help-icon {
  background-image: url(/src/static/graphics/help.svg);
  background-repeat: no-repeat;
  background-size: contain;
  width: 0.9em;
  height: 1.5em;
}

.tableCodeText {
  font-size: 0.75em;
  color: #eac242;
}
.tableCode {
  font-size: 1.15em;
  color: #fff;
  font-weight: bold;
}

.modalTitle {
  text-align: center;
}
.hostControlsNavigationPage {
  flex: 1;
}

.hostControlsButtonsContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 33% 33% 33%;
  grid-gap: 1em;
  gap: 1em;
  padding: 2em;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  flex: 1;
  box-sizing: border-box;

  background-color: rgba(2, 2, 11, 0.5);
  border-radius: 16px;
}

.hostControlsButton {
  display: flex;
  background-color: #02020b;
  font-size: 1.8em;
  text-align: center;
  align-content: center;
  justify-content: center;
  border: 1px solid #cbcbcd;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bolder;
  background-color: #06060c;
  cursor: pointer;
  color: #cbcbcd;
  text-align: center;
  flex-direction: column;
}
.hostControlsButton:hover:not(.inactive) {
  background: #f0c105;
  color: #272d2d;
  border-color: #ffffff80;
}
.hostControlsButton.inactive {
  cursor: default;
  color: #ababab;
}

.hostControlsButton .buttonText {
  align-self: center;
}
.hostControlsButton .buttonDetailText {
  font-size: 0.5em;
  font-weight: normal;
}

.hostControlsButton.lightDanger {
  grid-column: 1 / 3;
  background-color: #b53c3c;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
}
.hostControlsButton.lightDanger:hover:not(.inactive) {
  background-color: #d9253f;
  color: white;
}

.tableSettingsContentContainer {
  background-color: rgba(2, 2, 11, 0.5);
  height: 100%;
  width: 100%;
  border-radius: 16px;
}

.actForPlayerButtonsContainer {
  display: grid;
  grid-template-columns: 49% 49%;
  grid-template-rows: 30% 30% 30%;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  flex: 1 1;
  box-sizing: border-box;
  border-radius: 16px;
}
.hostControlsSubpageContainer {
  background-color: #02020b80;
  border-radius: 15px;
  box-sizing: border-box;
  height: 100%;
  padding: 0.5rem;
  overflow: auto;
}

.hostControlsSubpageContainer.tableSetting {
  background-color: transparent;
  padding: 0;
}

.hostControlsSubpageContainer .table {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0;
}

.hostControlsSubpageContainer .table .tableRow {
  overflow: auto;
  padding: 0.6rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.hostControlsSubpageContainer.tableSetting .table .tableRow {
  padding: 0;
}

.actForPlayerSubpage {
  height: 100%;
}

.actForPlayerSubpageButtonsContainer {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 33% 33% 33%;
  grid-gap: 1em;
  gap: 1em;
  padding: 1em 0.5em;
  width: 100%;
  height: 100%;
  align-content: center;
  justify-content: center;
  flex: 1 1;
  box-sizing: border-box;

  border-radius: 16px;
}

.exitModalButton {
  background-image: url(/src/static/graphics/exitModalButton.svg);
  background-repeat: round;
  background-size: cover;
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1em;
  height: 1em;
  z-index: 1;
}

/* host controls -> reset hand styling*/
.resetHandModalContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  grid-gap: 0.7em;
  align-self: center;
  width: 100%;
  height: 100%;
}

.resetHandTitleText {
  color: white;
  font-size: 1.8em;
  text-align: center;
  font-weight: 700;
}

.resetHandSubtext {
  color: white;
  font-size: 1.2em;
  text-align: center;
}

.resetHandResetButton.actionButton.green {
  color: white;
  font-size: 1.2em;
  text-align: center;
  border: 3px solid white;
  width: fit-content;
  padding: 0.8em 1.2em;
}

.resetHandCancelButton.actionButton.cancelButton {
  color: white;
  font-size: 0.8em;
  text-align: center;
  width: fit-content;
  padding: 0.5em 2em;
  flex: none;
}

.endVideoButton.actionButton {
  max-width: 19em;
  font-size: 1.2em;
}

.helpContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  border-radius: 12px;
  background-color: #02020b80;
  height: 100%;
  overflow: auto;
}

.myAccountPlaceholder {
  height: 100%;
  color: #e9e9e9;
  align-items: center;
  text-align: center;
}
.myAccountPlaceholder h2 {
  width: 100%;
}

.resetButtonSubtext {
  color: #fff;
}
.preShutdownHeader,
.postShutdownHeader {
  font-size: 0.75em;
}

.betaSurveyButton {
  font-size: 2em !important;
}

.timerDisplay {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  width: 21.5em;
  justify-content: space-between;
}

.postShutdownSubHeader {
  font-size: 0.6em;
  color: #f0c105;
}

@media only screen and (max-width: 768px) {
  .hostControlsButton .buttonText {
    font-size: 0.8em;
  }
  .hostControlsTableCode,
  .hostControlsHeaderOptions {
    top: 0.2em;
  }
}
