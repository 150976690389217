/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * This file specifically styles the "Fold" button rendered in between turns.
 */

.foldInTurnButton {
  font-size: 0.66rem;
  font-weight: 600;
  width: 3rem;
}
