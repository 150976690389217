/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for "ChipBetArea", a component that the UI to the right of the user's avatar in the action area.
 */

.noLimitChipBetArea :global(.chipContainer) {
  margin: 0 auto;
  cursor: pointer;
}

.noLimitChipBetArea :global(.chipContainer.disabled) {
  filter: opacity(0.7) brightness(70%);
  cursor: default;
  pointer-events: none;
}

.noLimitChipBetArea :global(.chipContainer):hover {
  /* TODO: Hover effect on chips? */
}

:global(.chipBetArea) .noLimitActionButton {
  font-size: 0.875rem;
  font-weight: 600;
}

.noLimitChipBetArea {
  display: grid;
  grid-template: 3.4rem 3.4rem / 1fr 1fr 1fr 1fr;
  gap: 0.25rem;
  height: 100%;
  align-items: center;
  margin-left: 0.33rem;
}

:global(.chipBetArea) .noLimitResetButton {
  font-size: 0.75rem;
}

:global(.chipBetArea) .allIn {
  font-size: 1rem;
}
