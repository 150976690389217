/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for "SidePotsTotals", a component that renders the current game's sidepot totals and game total.
 */

.sidepotsContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 10px;
  background-color: #333d32;
  border: 0.05rem solid black;
  border-radius: 0.75rem;
  color: #ffffff;
  font-size: 0.6rem;
}

.sidepots {
  --borderColor: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 0.05rem solid var(--borderColor);
  padding-bottom: 0.1rem;
}

.sidepotItem {
  display: flex;
  align-items: center;
  font-weight: bold;
  line-height: 0.75rem;
}

.sidepotItem:not(:first-of-type) {
  /*
   * It's Xrem here and Yrem on the next rule set because the tooltip itself already has margin.
   */
  padding-left: 0.4rem;
}

.sidepotItem:not(:last-of-type) {
  border-right: 1px solid var(--borderColor);
  padding-right: 0.35rem;
}

.totalInSidepots {
  display: flex;
  align-items: center;
}

.totalInSidepotsAmount {
  margin-left: 0.2rem;
  font-weight: 700;
}

.winner {
  color: #22d532;
  font-weight: bold;
}

.playerList {
  margin: 0;
  list-style: none;
  padding: 0;
}

.sidepotItemTooltipButton {
  padding: 0.15rem !important;
}
