.dashboard-container {
  margin: 2em auto;
  max-width: 95%;
}

.dashboard-container .played-games {
  color: white;
  margin: auto;
  font-size: 0.95em;
  padding: 1em;
  flex-direction: column;
  gap: 1em;
  overflow: auto;
}

.dashboard-container .caption {
  color: #efc005;
  font-weight: bold;
  text-align: center;
}

.dashboard-container .played-games table {
  width: 100%;
  border: 0;
  border-spacing: 0;
}

.dashboard-container .played-games table td,
.dashboard-container .played-games table th {
  padding: 0.5em 1em;
  text-align: center;
  vertical-align: top;
}

.dashboard-container .played-games table tbody tr:hover {
  background-color: #efc005;
  color: black;
}

.dashboard-container .page-detail {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard-container .page-info {
  color: #efc005;
  font-weight: bold;
}

.dashboard-container .pagination-area {
  display: flex;
  gap: 0.5em;
}

.dashboard-container .pagination {
  display: flex;
  gap: 1em;
  list-style: none;
}

.dashboard-container .activePage {
  color: #efc005;
}

.dashboard-container .pageLink {
  cursor: pointer;
}

.dashboard-container .previous.disabled,
.dashboard-container .next.disabled {
  cursor: none;
  pointer-events: none;
  color: #555555;
}

.dashboard-container .host-column {
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dashboard-container .options {
  padding: 1em;
  display: flex;
  justify-content: flex-end;
}

.dashboard-container .download-button {
  min-width: 18em;
}
