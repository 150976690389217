/**
 * This is a global CSS file which is meant to define styling for multiple components in this folder.
 * There is a CSS modules file with the same name and it's explicitly meant for the Seat component - not all components
 * in the folder. All new CSS should go in there. Not here.
*/

:root {
  --video-border-radius: 0.8em;
  --video-border-size: 2px;
  --winner-animation-duration: 750ms;
  --winner-iteration-count: 4;
}

.open {
  opacity: 0.1;
  transition: opacity 100ms ease-in-out;
}
.actionArea .open {
  opacity: 1;
}

.open:hover {
  opacity: 0.6;
}

.balance {
  grid-area: balance;
  color: white;
  font-weight: bolder;
}

.dealerIconContainer {
  height: 1.8rem;
  width: 1.8rem;
  position: absolute;
  z-index: 21;
  right: 0px;
  top: 4px;
}

.right .dealerIconContainer {
  height: 1.8rem;
  width: 1.8rem;
  position: absolute;
  z-index: 21;
  left: 4px;
  top: 4px;
}

.middle .dealerIconContainer {
  height: 1.4rem;
  width: 1.4rem;
  position: absolute;
  z-index: 21;
  right: 1px;
  top: 5px;
}

.dealerIndicator {
  background-image: url(/src/static/graphics/PlayerIconDealer.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.turnIndicatorContainer {
  height: 3rem;
  width: 3rem;
  position: absolute;
  z-index: 21;
  right: 0.5rem;
  top: -4.5em;
  animation: yourTurn 1400ms infinite;
}

.turnIndicator {
  background-image: url(/src/static/graphics/ArrowDown.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.filterWhite {
  filter: sepia(0%) saturate(0%) hue-rotate(325deg) contrast(200%);
}

@keyframes yourTurn {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(15%);
  }
  100% {
    transform: TranslateY(0);
  }
}

.right .turnIndicatorContainer {
  height: 3rem;
  width: 3rem;
  position: absolute;
  z-index: 21;
  left: -3.5em;
  top: 5.5em;
  animation: yourTurnRight 1400ms infinite;
}

.right .turnIndicator {
  background-image: url(/src/static/graphics/ArrowRight.svg);
  color: #f0c105;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

@keyframes yourTurnRight {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15%);
  }
  100% {
    transform: TranslateX(0);
  }
}

.left .turnIndicatorContainer {
  height: 3rem;
  width: 3rem;
  position: absolute;
  z-index: 21;
  right: -3.5em;
  top: 5.5em;
  animation: yourTurnLeft 1400ms infinite;
}

.left .turnIndicator {
  background-image: url(/src/static/graphics/ArrowLeft.svg);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

@keyframes yourTurnLeft {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15%);
  }
  100% {
    transform: TranslateX(0);
  }
}

.middle .seatSpot {
  height: 1.5em;
  line-height: 1.5em;
}

.middle .pause-text {
  position: relative;
  top: -0.3em;
}

.middle .pause-overlay {
  font-size: 0.7em;
}

.seatSpot {
  display: flex;
  justify-content: space-between;
  height: 1.8em;
  line-height: 1.8em;
  background: #000000;
  opacity: 0.8;
  border-radius: 0 0 var(--video-border-radius) var(--video-border-radius);
  z-index: 11;
  position: absolute;
  left: 0;
  color: white;
  bottom: 0;
  width: 100%;
  padding: 0 0.1em;
  box-sizing: border-box;
}

.seatSpot.seatHidden {
  display: none;
}

.playerInfoLeft {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
}
.playerInfoLeft:not(:hover) {
  text-overflow: ellipsis;
}
.playerInfoLeft.scrollText:hover span,
.playerInfoLeft.scrollText:focus span {
  display: inline-block;
  animation: scroll-text 8s linear 0s 1 normal;
}
.playerInfoMuted {
  font-size: 0.8em;
}

.seat {
  border: 1px solid black;
  will-change: box-shadow, color;
}

.foldContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 21;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-cards-container .foldContainer {
  visibility: hidden;
}

.foldIndicator {
  height: 2.6rem;
  width: 2.6rem;

  font-size: 1.8rem;
  text-align: center;
  line-height: 0.4rem;

  cursor: default;

  background-color: #a4292e;
  color: #e38984;

  border: 0.1rem solid #e38984;
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0 #6e1f22;
}

.foldIndicatorText {
  font-size: 0.6rem;
  font-weight: bold;
}

.breakContainer {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 22;
  --break-size: 6rem;
  height: var(--break-size);
  width: var(--break-size);
}

.middle .breakContainer {
  --break-size: 4rem;
}

.breakIndicator {
  background-image: url(/src/static/graphics/PlayerIconTakingBreak.svg);
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 22;

  transform: translate(-50%, -30%);
}

.right .playerRow {
  grid-template-areas: "cards camera";
  grid-template-columns: auto min-content;
  grid-template-rows: auto;
}

.playerRow {
  width: 100%;
  height: 100%;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  grid-template-areas: "camera cards";
  background: #3e5255;
  border-radius: var(--video-border-radius);
  position: absolute;
  z-index: 5;

  border: 1px solid black;
  box-sizing: border-box;
}
.playerRow > * {
  z-index: 10;
}
.playerRow .twilio-media {
  z-index: 9;
}
.passesIndicator {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.44);
  border-radius: 0.5em;
  padding: 0.2em;
  padding-bottom: 0.3em;
}
.left .passesIndicator {
  right: 10px;
  top: 29%;
}
.right .passesIndicator {
  left: 10px;
  top: 29%;
}
.actionAreaContainer .passesIndicator {
  left: 4px;
  top: 25%;
}
.passDot {
  border: 2px solid #ddd;
  border-radius: 50%;
  width: 0.4em;
  height: 0.4em;
  margin-bottom: 0.3em;
}
.passDotFilled {
  background-color: #ddd;
}
.passDot:last-child {
  margin-bottom: 0;
}

.seatContainer {
  width: min-content;
  gap: 0.5rem;
  position: relative;
  aspect-ratio: 4/3;
  border-radius: var(--video-border-radius);
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.44);
  z-index: 1;
}

.overlapHandler {
  z-index: 0;
}

.right .seatContainer,
.left .seatContainer {
  position: relative;
  flex: 1 0 10px;
  min-width: 14rem;
  margin-bottom: 0.2rem;
}

.middle .seatContainer {
  width: 9em;
  flex: unset;
  flex-shrink: 0;
  font-size: 0.8em;
}

.open-seat:hover {
  color: #cecece;
  animation: none;
  box-shadow: 0 0 5px 0 gold;
}

.open-seat:active {
  color: #505093;
  box-shadow: 0 0 0 0 gold;
}

.open-seat {
  font-weight: bolder;
  opacity: 0.95;
}

.right .twilio-media {
  justify-content: flex-end;
}

.twilio-media {
  grid-area: camera;

  height: 100%;
  width: 100%;
  min-height: 0;
  min-width: 0;
  justify-content: left;

  position: relative;
}

video {
  width: 100%;
  height: 100%;
  border-radius: var(--video-border-radius);
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.44);
  z-index: 2;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
}

video.hidden-video {
  display: none;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 0 #3444c9;
    color: white;
  }
  50% {
    box-shadow: 0 0 10px 2px #2c3aad;
    color: #7f83bb;
  }
  100% {
    box-shadow: 0 0 0 #3444c9;
    color: white;
  }
}

.button-glow {
  animation: glowing 2500ms infinite;
}

@keyframes activeGlow {
  0% {
    opacity: 0.5;
  }
  40% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.active:after {
  animation: activeGlow 2000ms infinite;
  border: 1em solid #f8f5df;
  border-radius: var(--video-border-radius);
  box-shadow: 0px 0px 10px 0px #fffdf0bf;
}

.winner:after,
.active:after {
  animation: activeGlow var(--winner-animation-duration)
    var(--winner-iteration-count);
  position: absolute;
  content: "";
  --border-offset: -0.3em;
  left: var(--border-offset);
  top: var(--border-offset);
  right: var(--border-offset);
  bottom: var(--border-offset);
  border-radius: var(--video-border-radius);
}

.winner.declaredHigh:after {
  border: 1em solid #1a9e27;
  border-radius: var(--video-border-radius);
  box-shadow: 0px 0px 10px 0px #1a9e27;
}

.winner.declaredLow:after {
  border: 1em solid #00a3ff;
  border-radius: var(--video-border-radius);
  box-shadow: 0px 0px 10px 0px #00a3ff;
}

.winner.declaredBoth:after {
  border: 1em solid #f0c105;
  border-radius: var(--video-border-radius);
  box-shadow: 0px 0px 10px 0px #f0c105;
}

.left .bankButton {
  margin-top: 5px;
  border-bottom: solid;
  border-width: thin;
  border-radius: 5px;
  padding: 0 4px;
  cursor: pointer;
  font-size: small;
  color: white;
  position: absolute;
  z-index: 21;
  left: 9px;
  top: 4px;
  opacity: 0.45;
}

.right .bankButton {
  margin-top: 5px;
  border-bottom: solid;
  border-width: thin;
  border-radius: 5px;
  padding: 0 4px;
  cursor: pointer;
  font-size: small;
  color: white;
  position: absolute;
  z-index: 21;
  right: 6px;
  top: 4px;
  opacity: 0.45;
}

.middle .bankButton {
  margin-top: 5px;
  border-bottom: solid;
  border-width: thin;
  border-radius: 5px;
  padding: 0 4px;
  cursor: pointer;
  font-size: small;
  color: white;
  position: absolute;
  z-index: 21;
  left: 9px;
  top: 4px;
  opacity: 0.45;
}

.kickButton {
  top: 15px !important;
}

.bankButton {
  user-select: none;
  background: black;
}

.bankButton:hover {
  opacity: 1;
}

@keyframes scroll-text {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-85%);
  }
  100% {
    transform: translateX(0%);
  }
}
