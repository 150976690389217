/*
  ChipPurchased.css
  This file contains the styles used for the chip purchased component
*/
.chipPurchased {
  padding: 1.2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.45em;
}

.chipPurchasedText {
  color: #f0c105;
  font-weight: bold;
  font-size: 1.5em;
  text-align: center;
}

.chipPurchasedAmount {
  color: #fff;
  font-weight: bold;
  font-size: 2.55em;
  margin: 0;
}

.chipPurchasedButton {
  font-size: 1.1em;
  margin-top: 0.55em;
  font-weight: 600;
  padding: 0.65em 2em;
}
