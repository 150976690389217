.table-cards-container {
  left: 50%;
  top: 45%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  /**
  * We add z-index as 3 here to have the second-highest z-index among middle components
  */
  z-index: 3;
}

.table-cards-container .cards {
  right: unset;
  transform: translateX(-50%);
}

.table-cards-container .card {
  bottom: unset;
}
