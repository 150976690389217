.widgetContainer {
  box-sizing: border-box;
  width: 13em;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  justify-content: center;
  pointer-events: none;
}
.widgetCenter {
  display: flex;
  flex-direction: column;
  gap: 1em;
  pointer-events: all;
}
