.button.createDevTable {
  border: 0;
  margin-top: 0.4em;
  align-self: center;
  max-width: 100%;
  box-sizing: border-box;
  padding-left: 2em;
  padding-right: 2em;
  white-space: nowrap;
  width: fit-content;
  font-size: 0.75em;
}

.createDevTable:hover {
  filter: brightness(0.9);
}
