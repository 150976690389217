/**
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * SeatWinnerBadge is the text content that displays at the top of the video element for a
 * winner at the end of the game. It says "WINNER HIGH", "WINNER LOW", or "WINNER BOTH".
*/

.seatWinnerBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  color: #ffffff;
  font-weight: 700;
  font-size: 12px;

  position: absolute;
  z-index: 25;
}

.tableSideSeats {
  font-size: 16px;
}

.seatWinnerBadge .seatWinnerBadgeText {
  text-align: center;
  background: #272d2d;

  padding: 0.1rem 0.4rem 0.2rem;

  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
