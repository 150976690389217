.lobbyTableCodeContainer {
  position: absolute;
  top: -2em;
  width: 100%;
  left: 0;
  align-items: center;
}
.lobbyTableCodeText {
  display: flex;
  justify-content: center;
  background-color: #000;
  margin: 0 auto;
  width: fit-content;
  color: rgb(239, 192, 5);
  font-size: 0.8em;
  padding: 0.2em 0.5em;
  border-radius: 0.2em;
  font-weight: bold;
}
.lobbyTableCode {
  color: #fff;
}
