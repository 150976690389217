.endSessionNonHostSubHeader {
  color: rgb(239, 192, 5);
  font-weight: bold;
  font-size: 1.2em;
}

.endSessionNonHostSubHeader .buttonContainer {
  display: flex;
  margin: 0.3em;
  justify-content: center;
}

.timerDisplay {
  display: flex;
  align-items: center;
  font-size: 1.2em;
  width: 21.5em;
  justify-content: space-between;
}
