/*
*   GameTypeEntry.css
*   Used to style element of GameTypeEntry component
*/
.gameSelectEntryBox {
  width: 100%;
  position: relative;
  flex-basis: 25%;
  display: flex;
}

.gameSelectEntryContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.gameSelectEntry {
  user-select: none;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 1em;
  line-height: 120%;

  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem;

  /* or 22px */
  text-align: center;

  /* Button / Default */
  background: #02020b;

  /* Text/Subtle */
  border: 2px solid #808085;
  box-sizing: border-box;
  border-radius: 8px;

  margin: 4px 8px;
  position: relative;
  overflow: hidden;
}

.premium {
  flex-direction: column;
  border: 2px solid #f0c105;
}

.premium.disabled {
  background-color: rgba(0, 0, 0, 0.5);
  border-color: rgba(80, 80, 85, 0.5);
  color: rgba(80, 80, 85);
  pointer-events: none;
}

.premiumText {
  background: #f0c105;
  color: #272d2d;
  border-radius: 4px;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 0.65em;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.gameSelectEntry:hover .premiumText {
  background: #272d2d;
  color: #f0c105;
}

.gameText {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.gameSelectEntry:hover {
  background: #f0c105;
  color: #272d2d;
  border-color: #ffffff80;
}

.gameTypeRibbon {
  position: absolute;
  width: 132px;
  background-color: #f0c105;
  transform: rotateZ(334deg);
  top: 7px;
  left: -32px;
  text-align: center;
  color: #272d2d;
  font-size: 0.65rem;
}
