.authWidgetContainer {
  box-sizing: border-box;
  width: fit-content;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 1em;
  top: 1em;
  gap: 1em;
}

.SignInOutButton {
  cursor: pointer;
  user-select: none;
  color: white;
  width: 100%;
  height: 2.5em;
  border: 2px solid #808085;
  background-color: rgba(0, 0, 0, 0.55);
  /* padding: 0.5rem; */
  border-radius: 8px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 700;
  overflow-wrap: break-word;
}

.authButtonColumn {
  box-sizing: border-box;
  width: 7em;
  display: flex;
  flex-direction: column;
  gap: 8px;
  right: 5px;
  top: 1%;
}

.authButtonIcon {
  margin-right: 6%;
  height: 45%;
}
