.columnLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.freeTrial {
  background-color: #02020b;
  color: #f0c105;
  width: 100%;
  border-radius: 0.5em;
  font-weight: bold;
  padding-top: 2px;
  padding-bottom: 2px;
}

.customAlert {
  background-color: #b53c3c;
  color: #ffffff;
  padding-top: 2px;
  padding-bottom: 2px;
}

.smallerButton {
  font-size: 0.8em !important;
  margin-left: auto;
  margin-right: auto;
}
