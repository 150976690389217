/*

  TwilioErrorMessage.module.css
  styles for the eponymous component,
  a dismissible popup which displays near the local user's seat
  to notify the user of twilio errors.
  ::after renders the popup triangle / pointer

*/

.errorMessage {
  font-size: 0.7rem;
  color: #333;
  padding: 0.5em;
  position: absolute;
  top: 0;
  background-color: white;
  font-weight: bold;
  width: 150%;
  padding: 0.75em;
  border-radius: 0.75em;
  right: 110%;
}
.errorMessage::after {
  content: "";
  position: absolute;
  top: 35%;
  left: 100%;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #fff;
  clear: both;
  z-index: 99;
}
.popupClose {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
