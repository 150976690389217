/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for "Pot", a component that renders the current game's pot.
 */

.potContainer {
  font-weight: bold;
  color: #eaeaea;
  text-shadow: 0 0 2px rgb(0 0 0 / 15%);
  font-size: 1.1rem;
  display: flex;
  justify-content: center;
  /**
  * We add z-index as 2 here to have the lowest z-index among middle components
  */
  z-index: 1;
}

.potAmount {
  background-color: #3f673d;
  border-radius: 1.3em;
  box-shadow: inset 0em 0.2em 0.2em rgba(39, 65, 37, 0.5);
  min-width: 7.9rem;
  height: 2.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.practiceModeContainer {
  background-color: #ecb60a;
  padding: 0.5em 0.4em 0.4em;
  border-radius: 1.6em;
}

.botModeContainer {
  background-color: #5baae7;
  padding: 0.5em 0.4em 0.4em;
  border-radius: 1.6em;
}

.practiceModeText {
  white-space: nowrap;
  font-size: 0.75em;
  color: #323237;
  margin-bottom: 0.2em;
  padding: 0.2em;
}
