@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Open Sans", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  font-size: 2.2vh;
  overflow: auto;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #5f8358;
  background-image: url(/src/static/graphics/TableWithBackgroundWithoutLogo.jpg);
  background-position: center;
  background-size: cover;
}

/**
  this Cow logo svg is hosted by us and used on the Auth0 login page,
  though actually unused within our app,
  so to make sure it's packaged and deployed to the server we
  need to pretend to use it here in our app:
*/
.auth0ImageAsset {
  height: 0;
  width: 0;
  background-image: url(/src/static/graphics/Cow.svg);
}

#root,
.App {
  overflow: auto;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

.removed {
  display: none;
}

/**
 * This css is for rendering text for users of assitive technology. An element with these styles
 * will be rendered, but invisibly. Not every screen reader is compatible with `aria-label`,
 * so this should be used instead.
 *
 * Note: If you use this CSS, we encourage you to apply it within a `span` so that you don't risk
 * CSS nesting validation issues. Please be aware of potential invalid HTML if you don't put it in
 * a span.
 *
 * One example: If attempting to create a custom radio or checkbox input, you will want the actual
 * input rendered, and - in order to style it without css - the order of the elements matter. This
 * means that a `ScreenReaderOnly` component would make it impossible to use sibling styles. Since,
 * `:has()` has limited browser support, this has been exposed for your needs.
 *
 * "sr-only" means: "Screan Reader Only"
 *
 * We use `!important` to prevent accidents. If somebody styles an element with the "sr-only" class,
 * it’s absolutely imperative that none of these properties get overridden. If they do, the element
 * will be visible in some regard. We could get higher specificity by putting the values into the
 * style attribute, but then we lose out on cacheability and hurt the bundle size.
 */
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  white-space: nowrap !important;
  width: 1px !important;
}

/**
 * This css is for disabling animation for users who have requested 
 * to minimize the amount of non-essential motion
 *
 * Note: This class is applied mainly to be injected for CI ie. when running tests
 * This allows the VRT tests to take screenshot w/o said flakiness
 *
 * More: https://developer.mozilla.org/en-US/docs/Web/CSS/@media/prefers-reduced-motion
 */
@media (prefers-reduced-motion) {
  * {
    animation: none !important;
  }
}
