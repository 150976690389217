/*
* LowChip.css
* This css used to style the low chip component
*/

.lowChipContent {
  display: flex;
  padding: 1.5em;
  justify-content: center;
}

.lowChipButtons {
  display: flex;
  flex-direction: column;
  gap: 1em;
  flex: 1;
  max-width: 26rem;
}

.requestChipButton {
  padding: 1.25em;
  font-size: 1em;
}

.lowChipBackButton {
  width: 10rem;
  align-self: center;
  padding: 1em;
}
