:root {
  --card-distance: 0.2rem;
  --card-margin: 1.7em;
}

.playerRow .outside {
  top: 0;
  right: -1rem;
  transform: translateX(100%);
}

.cardDiscarded {
  top: 0.5em !important;
}

.cardSelected {
  filter: brightness(45%) !important;
}

.right .playerRow .outside {
  left: -1rem;
  transform: translateX(-100%);
}

.cards.outside {
  align-items: flex-start;
}

.cards {
  position: absolute;
  bottom: -3px;
  z-index: 20;
  right: 0.9rem;
  height: min-content;
  display: flex;
  align-items: flex-end;
  width: max-content;
}

.playerRow .cards {
  margin-right: calc(var(--card-margin) * -1);
}

.playerRow .cards.folded {
  margin-right: 0;
}

.playerRow .cards:not(.outside) {
  overflow: hidden;
}

.right .cards {
  right: auto;
  left: 0.9rem;
}

.overlapped .card {
  margin-left: calc(var(--card-margin) * -1);
  box-shadow: -1px 0px 3px -1px rgba(0, 0, 0, 0.6);
}

.userCardBetColumn .overlapped.selectingDiscard .card {
  margin-left: 0.2em;
}

.cards {
  transition: opacity, background 1s;
}

.card.private-wild.wild {
  background-color: #66d5e5;
}

.card.wild {
  background-color: #f1da58;
}

.opponent-card {
  background-color: white !important;
}

.cross-container .card:not(.down) {
  animation: flip 1s;
}

.winner .cards.outside {
  transition: top 1s ease-in-out;
  bottom: unset;
  top: 0;
}

.winner .cards {
  transition: bottom 1s ease-in-out;
  bottom: 1em;
  animation: unset;
}

.hiddenWithBox {
  visibility: hidden;
}

.selected-group.leftPlayers {
  margin-left: calc(var(--card-margin));
}
.selected-group.rightPlayers {
  margin-right: calc(var(--card-margin) + 0.3em);
}

.selected-group .card {
  --size: 1.5;
}

.right .selected-group {
  order: -1;
}

.hole-card {
  position: relative;
  top: 0.5rem;
  filter: brightness(65%);
}

.selecting .card:not(.fullBrightness, .showdownOverride, .darkOverlay) {
  filter: brightness(65%) !important;
}

.selecting .card:not(.hole-card) {
  filter: brightness(100%) !important;
}

.card.selected {
  display: none;
}

.card.fullBrightness,
.card.showdownOverride {
  opacity: 1;
  filter: brightness(100%);
}

.card.darkOverlay::after {
  background-color: rgba(0, 0, 0, 0.7);
}

.card::after {
  content: "";
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: inherit;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s linear;
}

/* This is seemingly unused and has been for a long time */
@keyframes fade {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes flip {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
