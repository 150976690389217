.connection-lost {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 45%;
  height: max-content;
  background-color: rgba(255, 59, 59, 1);
  z-index: 100;
  display: flex;
  border-radius: 10px;
  padding: 0.25em 1em;
  transition: all 0.2s;
  margin: 0.5em;
  border: 1px solid rgba(162, 16, 16, 0.7);
  font-size: 0.8em;
}

.connection-lost-hidden {
  opacity: 0;
}
