/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file.
 * We use rgba values here so that we can manipulate the opacity of JUST the border color and box-shadow colors.
 * We don't want to adjust the opacity for the entire element, as it would affect the opacity of all children
 * elements as well.
 */

:root {
  --winGlowBoxBorderWidth: 3px;
  --winGlowBoxAnimationDuration: 750ms;
  --winGlowBoxIterationCount: 4;
}

/* #f0c105 */
@keyframes goldGlow {
  0% {
    border-color: #f0c10580;
  }
  40% {
    border-color: #f0c105;
  }
  60% {
    border-color: #f0c105;
  }
  100% {
    border-color: #f0c10580;
  }
}

/* #1a9e27 */
@keyframes greenGlow {
  0% {
    border-color: #1a9e2780;
  }
  40% {
    border-color: #1a9e27;
  }
  60% {
    border-color: #1a9e27;
  }
  100% {
    border-color: #1a9e2780;
  }
}

/* #00a3ff */
@keyframes blueGlow {
  0% {
    border-color: #00a3ff80;
  }
  40% {
    border-color: #00a3ff;
  }
  60% {
    border-color: #00a3ff;
  }
  100% {
    border-color: #00a3ff80;
  }
}

.bothWin {
  animation: goldGlow var(--winGlowBoxAnimationDuration)
    var(--winGlowBoxIterationCount);
  border: var(--winGlowBoxBorderWidth) solid #f0c105;
}

.highWin {
  animation: greenGlow var(--winGlowBoxAnimationDuration)
    var(--winGlowBoxIterationCount);
  border: var(--winGlowBoxBorderWidth) solid #1a9e27;
}

.lowWin {
  animation: blueGlow var(--winGlowBoxAnimationDuration)
    var(--winGlowBoxIterationCount);
  border: var(--winGlowBoxBorderWidth) solid #00a3ff;
}
