/**
 * Button.module.css
 *
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically handles the case when button needs to be disabled when request is pending
 */
.ghost {
  filter: opacity(0.7) brightness(70%) !important;
  cursor: default;
  pointer-events: none;
}

.base {
  all: unset;
  cursor: pointer;
  user-select: none;
  color: white;
  border: 0.125rem solid #808085;
  background-color: #02020b;
  padding: 0.5rem;
  border-radius: 0.5rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 700;
  overflow-wrap: break-word;
}

.base:hover {
  background-color: #323232;
}

.base:disabled,
.base.disabled {
  cursor: default;
  pointer-events: none;
}

/* These values don't match the tabs buttons in the design either...  */
.base.gray {
  --color: #646464;
  color: #9b9b9b;
  background-color: var(--color);
  font-weight: bolder;
}

.base.gray:hover {
  filter: none;
}

.base.green {
  --color: #1a9e27;
  background-color: var(--color);
  border: 0.125rem solid #ffffff80;
  font-weight: bolder;
}

.base.green:hover {
  background-color: #7eb357;
}

.base.yellow {
  --color: #eac000;
  background-color: var(--color);
  border: 0.125rem solid #ffffff80;
  font-weight: bolder;
}

.base.yellow:hover {
  background-color: #e4d03b;
}

.base.red {
  --color: #b53c3c; /* TODO: Why "#C63943" on Figma for account page? */
  background-color: var(--color);
  border: 0.125rem solid #ffffff80; /* TODO: Why no border on Figma for account page? */
  font-weight: bolder;
}

.base.red:hover {
  background-color: #c45e5e;
}

.base.blue {
  --color: #3a8de0;
  background-color: var(--color);
  border: 0.125rem solid #ffffff80;
  font-weight: bolder;
}

.base.blue:hover {
  background-color: #5baae7;
}

.base.black {
  --color: #000000;
  background-color: var(--color);
  border: 0.125rem solid #ffffff80;
  font-weight: bolder;
}

.base.black:hover {
  background-color: #292929;
}
