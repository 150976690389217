.declareActions {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.declareButtonsContainer {
  display: flex;
  justify-content: space-evenly;
}

.declareTitleText {
  color: #f0c105;
  font-weight: bold;
  font-size: larger;
  margin-bottom: 8px;
}

.declareIcon {
  margin-right: 0.3em;
}
