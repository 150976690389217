.player-bet {
  --pillHeight: 2rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1a9e27;
  border: 0.1rem solid #ffffff;
  border-radius: var(--pillHeight);
  width: 5rem;
  height: var(--pillHeight);
  text-align: center;
  margin: 0;
  transition: opacity 0.4s;

  /**
  * To have the top of the indicator be flush with the bottom of their cards.
  */
  top: calc(54% + 5px);

  /**
  * We add z-index as 6 here to handle hand-value overlap ie. .playerRow has z-index: 5
  */
  z-index: 6;
}

.player-bet.checked {
  background-color: #f0c105;
}

.player-bet.allIn {
  background-color: #a52acb;

  /**
  * To have the top of the indicator be flush with the bottom of their cards.
  * A bit further because we want to align the "All In" badge which makes this element a bit taller.
  */
  top: calc(54% + 8px);
}

.seatingArea .player-bet.allIn.folded {
  background-color: #a52acb;

  /**
  * To have the top of the indicator be flush with the bottom of their cards.
  * A bit further because we want to align the "All In" badge which makes this element a bit taller.
  */
  top: calc(54% + 8px);
}

/*
* Apply top class to player-bet when no cards exist for player 
*/
.player-bet.top {
  top: -0.525rem !important;
}

.player-bet.allIn.folded {
  top: -1.875rem;
}

.player-bet.allIn::after {
  content: "ALL IN";
  background-color: #ffffff;
  border-radius: 0.1rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: #272d2d;
  font-size: 0.7rem;
  position: absolute;
  bottom: 1.7rem;
}

.hidden {
  opacity: 0;
}

.left .player-bet {
  transform: translate(100%, 50%);
  right: -1rem;
}

.right .player-bet {
  transform: translate(-100%, 50%);
  left: -1rem;
}
