/*
 * This is a CSS Modules file which serves to export styling SOLELY for the sibling TSX file. This file specifically
 * hosts styles for "HandValue", a component that renders one or two "HandValueItem" components to describe the low
 * value, the high value, or both values of a hand.
 */

.handValue {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
  min-height: 2.2rem;
}

:global(.middle) :global(.cards) .handValue {
  display: none;
}

:global(.middle) .handValue.highOnlyHandValue {
  flex-direction: row-reverse;
}

:global(.left) .handValue {
  left: 0;
  margin-bottom: 0;
  margin-left: calc(var(--card-margin) * -1 - 0.35rem);
}

:global(.right) .handValue {
  right: 0;
  margin-bottom: 0;
}

:global(.left) .handValue,
:global(.right) .handValue {
  width: max(100%, 220px);
  position: absolute;
  top: 5rem;
}

:global(.left) .handValue.bothHandValues,
:global(.right) .handValue.bothHandValues {
  display: flex;
  flex-direction: column-reverse;
}

.handValue:not(.winningHandValue) {
  gap: 0.1rem;
}

.bothHandValues.winningHandValue .handValueItem:not(.local):last-of-type {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bothHandValues.winningHandValue .handValueItem:not(.local):first-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/** This matches the border-width of the win glow, so that the local handValueItems don't move 3px upon win. */
.handValue:not(.winningHandValue) .handValueItem.local {
  margin: var(--winGlowBoxBorderWidth);
}

.handValueItem:not(.local) {
  width: 100%;
}

/* flip icon and eye button */
:global(.right) .handValueItem:not(.local) {
  flex-direction: row-reverse;
}

.boldDescription {
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  :global(.right) .handValue {
    width: 220px;
  }
}
