/**
* Phone.module.css
* Styling for phone component
*/

.phoneContent {
  min-width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25em;
  position: relative;
  padding: 0.6em;
}

.title {
  font-size: 1.3em;
  color: #fff;
  font-weight: 600;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 2.2em;
  width: 2.2em;
}

.closeIcon {
  font-size: 1.5em;
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.groupBoxPhoneNumber {
  display: flex;
}

.countryCode {
  background: #ccc;
  color: #000;
  padding: 0.45em 0.75em;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  font-size: 0.95em;
  font-weight: 600;
}

.footer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.inputPhoneNumber {
  display: flex;
  padding: 0.65em 0.75em;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: none;
  height: 100%;
  flex: 1;
  height: 100%;
  font-size: 0.9em;
}
