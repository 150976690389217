/*
* Loader.module.css
* This module css used for styling loader component with spin animation
*/
.loader {
  border: 0.25em solid #d6d6d6; /* Light grey */
  border-top: 0.25em solid #818181; /* Blue */
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation: spin 2s linear infinite;
  margin: 0 4px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
