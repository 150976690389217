.waitingArea {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
}
.waitingArea.waitingCentered {
  justify-content: center;
}
.waitingArea .actionButton {
  height: 100%;
  box-sizing: border-box;
  white-space: pre-line;
}
.waitingArea .actionButton.selected {
  font-weight: bolder;
}
.waitingArea .actionButton.foldInTurnButton {
  font-size: 0.6em;
  margin-left: 0.3em;
}
.checkCallInTurnButton {
  font-size: 1em;
  margin-right: 0.3em;
}
.waitingArea .foldInTurnButton.selected {
  padding: 0.2em;
}
