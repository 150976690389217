.media-controls {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-self: end;
  flex: 1;
  justify-content: space-between;
  height: 100%;
}
.media-controls .actionButton {
  width: 3rem;
  height: 3rem;
  font-size: 1.6rem;
}
