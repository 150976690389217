.JoinGameWidget {
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: white;
  background-color: #333d32;
  border-radius: 0.75em 0px 0px 0.75em;
  padding: 0.75em;
  gap: 1em;
  border: 2px solid #272d2d;
}
.JoinGameWidget.JoinGameWidgetError {
  grid-gap: 0.15em;
}
.JoinGameWidgetErrorContainer {
  max-width: 100%;
  box-sizing: border-box;
}

.JoinGameWidgetInput {
  height: 2em;
  font-size: 1em;
  text-align: center;
  font-weight: 700;
  border-radius: 8px;
  max-width: 100%;
}
.JoinGameWidgetInput::placeholder {
  font-size: 1em;
  color: #c1c1c1;
}

.JoinGameButton {
  cursor: pointer;
  user-select: none;
  color: white;
  width: 100%;
  height: 2.5em;
  border: 2px solid #808085;
  background-color: rgba(0, 0, 0, 0.55);
  padding: 1rem;
  margin: 10px 4px 0px 4px;
  border-radius: 8px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  font-weight: 700;
  overflow-wrap: break-word;
}
.JoinGameError {
  font-size: 0.65rem;
  color: red;
  padding: 0.3rem 0.3rem 0rem 0.3rem;
  font-weight: bold;
  text-align: center;
}
