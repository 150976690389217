.foldArea {
  width: 4em;
  margin-right: 0.75em;
}
.foldArea .foldButton,
.foldArea .foldInTurnButton,
.foldArea .showCardsButton {
  height: 100%;
  box-sizing: border-box;
}
