.playerDeclareIndicatorContainer {
  display: flex;
  overflow: visible;
  height: min-content;
  width: 1.4em;
  flex-direction: column;

  position: absolute;
  top: 0.8em;
  right: 0.2em;

  color: white;

  border-radius: 50%;
  background: #d27fc9;
  box-sizing: border-box;

  box-shadow: #2c2c2c 0 0 3px;

  padding: 0.2em;
}

.declareIndicatorHigh {
  background: #1a9e27;
}

.declareIndicatorLow {
  background: #018cda;
}

.declareIndicatorBoth {
  background: #eac000;
  width: 1.5em;
  padding: 0.1em;
}

.playerDeclareIndicatorContainer:empty {
  display: none;
}
