.actionAreaWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  /**
  * We add z-index as 4 here to have the highest z-index among middle components
  */
  z-index: 4;
}
.actionArea {
  box-sizing: border-box;
  height: min-content;
  min-width: min-content;
  width: 100%;
  max-width: 40rem;
  position: relative;
}

.actionAreaCallout {
  color: #efc005;
  font-weight: bold;
  position: relative;
  font-size: 1em;
}

.visibleActionArea {
  width: 100%;
  color: #ccced0;
  padding-left: 1em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.waitingText {
  margin: auto 0;
  height: max-content;
  font-weight: bold;
}
.waitingTextRaises {
  margin: 0 auto;
  height: 100%;
  font-weight: bold;
  padding-top: 0.2em;
  flex: 1;
}

.actionAreaContainer {
  position: relative;
  height: min-content;
  border-radius: 15px;
  padding: 0.5em;
  display: flex;
  flex-direction: row;
  background-color: #333d32;
  border: 2px black solid;
}

.userCardBetColumn {
  bottom: unset;
  right: unset;
  top: 0;
  left: 50%;
  transform: translate(-50%, -90%);
  position: absolute;
}

.userCardBetColumn .card {
  bottom: unset;
}

.userCardBetColumn .cards {
  top: 2rem;
  bottom: unset;
  right: unset;
  z-index: 0;
}

.userCardBetColumn .cards.folded {
  top: -1rem;
}

.userCardBetColumn .cards,
.userCardBetColumn .player-bet {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-left: var(--card-margin);
}

.userCardBetColumn .player-bet {
  top: 2.125rem;
}

.actionArea .seatContainer .player-bet {
  display: none;
}

.actionArea #Seat {
  display: flex;
  flex: 1;
}

.actionArea #BetArea {
  display: flex;
  flex: 3;
}

.actionArea .actionButton {
  border: 0.125rem solid #808085;
}

.declareButtonsContainer .actionButton {
  padding: 0.8rem 1.25rem;
  font-size: larger;
  width: 6rem;
  max-height: 70px;
}

.declareButtonsContainer .actionButton.long {
  width: 10em;
}

.actionButton {
  cursor: pointer;
  user-select: none;
  color: white;
  border: 0.125rem solid #808085;
  background-color: #02020b;
  padding: 0.5rem;
  border-radius: 5px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  overflow-wrap: break-word;
}

.actionButton:hover {
  background-color: #323232;
}

.actionButton:disabled {
  cursor: default;
  pointer-events: none;
}

.actionButton.disabled {
  cursor: default;
  pointer-events: none;
}

.gray {
  --color: #646464;
  color: #9b9b9b;
  background-color: var(--color) !important;
  font-weight: bolder;
}

.gray:hover {
  filter: none !important;
}

.green {
  --color: #1a9e27;
  background-color: var(--color) !important;
  border: 0.125rem solid #ffffff80 !important;
  font-weight: bolder;
}

.green:hover {
  background-color: #7eb357 !important;
}

.yellow {
  --color: #eac000;
  background-color: var(--color) !important;
  border: 0.125rem solid #ffffff80 !important;
  font-weight: bolder;
}
.yellow:hover {
  background-color: #e4d03b !important;
}

.red {
  --color: #b53c3c;
  background-color: var(--color) !important;
  border: 0.125rem solid #ffffff80 !important;
  font-weight: bolder;
}
.red:hover {
  background-color: #c45e5e !important;
}

.blue {
  --color: #3a8de0;
  background-color: var(--color) !important;
  border: 0.125rem solid #ffffff80 !important;
  font-weight: bolder;
}
.blue:hover {
  background-color: #5baae7 !important;
}

.passDeckText {
  color: #f0c105;
  font-weight: 700;
}

.showdownArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  gap: 0.5rem;
}
.practiceHandCompleteButtons {
  flex-direction: row;
  display: flex;
  gap: 0.5rem;
  flex: 1;
}
.practiceHandCompleteButtons .actionButton {
  flex: 1;
  font-weight: bold;
}
