/**
* Email.module.css
* Styling for email component
*/

.emailContent {
  min-width: 20em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.25em;
  position: relative;
  padding: 0.6em;
}

.title {
  font-size: 1.3em;
  color: #fff;
  font-weight: 600;
}

.closeButton {
  position: absolute;
  right: 0;
  top: 0;
  height: 2.2em;
  width: 2.2em;
}

.closeIcon {
  font-size: 1.5em;
}

.content {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.groupBoxEmailNumber {
  display: flex;
  position: relative;
}

.footer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}

.inputEmail {
  display: flex;
  padding: 0.65em 0.75em;
  border-radius: 0.5rem;
  border: none;
  height: 100%;
  flex: 1;
  height: 100%;
  font-size: 0.9em;
}

.loader {
  position: absolute;
  top: 0.35em;
  right: 0.25em;
}

.emailExistText {
  align-self: flex-start;
  padding: 0.25em 1em;
}
