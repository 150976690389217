/*
* Tooltip.css
* Styling tooltip component with custom tooltip info icon
*/
.tooltipArrow {
  width: 0;
  height: 0;
  border-left: 0.45rem solid transparent;
  border-right: 0.45rem solid transparent;
  border-top: 0.45rem solid #fff;
}

.tooltipButton {
  all: unset;
  display: inline-block;
  margin-left: 0.25rem !important;
  width: 0.45rem;
  height: 0.45rem;
  cursor: pointer;
  background-image: url(/src/static/graphics/TooltipInfoIcon.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  padding: 0.25rem !important;
}

.tooltipTitle {
  font-weight: 700;
}

.tooltipContent {
  background-color: #fff;
  color: #333;
  padding: 0.3rem;
  border-radius: 0.35rem;
  font-size: 0.75rem;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0.75em;
  z-index: 99;
  max-width: 25rem;
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}

.tooltipContent[data-side="top"] {
  animation-name: slideUp;
}

.tooltipContent[data-side="bottom"] {
  animation-name: slideDown;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
