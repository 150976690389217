/**
* This is a global CSS file which serves to export styling primarily for the sibling TSX file.
* It is a global stylesheet and classes from this file are referenced in other global CSS and CSS module files.
* Consider using the CSS module of the same name for any new class names OR moving styles to that file during any
* refactors.
*/

.betChip {
  width: 3.5em;
  height: 3.5em;
  background-position: center;
  background-size: cover;
}

.betChip:hover {
  transform: translate(0px, -2px);
}

.betChip:active {
  filter: brightness(80%);
  transform: translate(0px, 0px);
}

.chipBetArea {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /* Inside auto layout */
  align-self: stretch;
  flex-grow: 1;
}

.dotsGroup {
  display: flex;
}

.raiseDot {
  border: 2px solid gray;
  border-radius: 50%;
  width: 0.6em;
  height: 0.6em;
  margin: 2px;
}

.raiseDotFilled {
  background: gray;
}

.chipBetArea .betButton {
  flex-grow: 0;
  width: 8em;
  font-size: 1rem;
}

.chipAreaRow {
  display: flex;
  width: 100%;
  grid-gap: 3em;
  justify-content: space-between;
}

.chipBetArea .actionButton {
  height: 100%;
  box-sizing: border-box;
}

.chips {
  margin-top: 0.1em;
  grid-gap: 3em;
}

.chipArea {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.2em;

  /* Inside auto layout */
  flex: 1;
  margin-left: 8px;
}
.actionButton.betResetButton {
  padding: 0.1rem 1rem;
  font-size: 0.8em;
  font-weight: bold;
}

.raisesCounter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 1rem;
  font-size: 0.8em;
  font-weight: bold;
  grid-gap: 0.4em;

  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 1em;
}
.waitingTextRaises .raisesCounter {
  margin-bottom: 1em;
  width: fit-content;
  float: right;
}
